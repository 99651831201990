import * as React from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import {StepIconProps} from '@mui/material/StepIcon';
import {AccountCircle, ArrowBack, ArrowForward, AttachMoney, Person, UploadFileSharp} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {RenderContainer} from '../..';
import {Form, Formik} from 'formik';
import {WLOCreditAPIService} from '../../../services';
import toast from 'react-hot-toast';
import {useIntl} from "react-intl";
import { useState } from 'react';
import {ModalReConfirm} from "../../Steps/RequestInfo/Modalconfirm/reConfirmInfo";
import Moment from 'moment';
import {AppRoutes} from "../../../Router";
import {useNavigate} from "react-router-dom";
import {useRequestContext} from '../../../hooks/useRequestContext';
import {useLoginContext} from '../../../hooks/useLoginContext';
import _ from 'lodash';
import {ColorlibConnector, ColorlibStepIconRoot, Item} from "../../../utils/utils";
import { type } from 'os';
import './Wizard.scss'
import { useEffect } from 'react';
interface WizardsProps {
    steps: ({key: string, label: string, initialValues: any, validationSchema: any})[];
    setPopup: React.Dispatch<React.SetStateAction<string>>;
    onUpdateLineaCredito: (newValue: string) => void;
}

export function Wizard(props: WizardsProps) {
    const { steps,setPopup,onUpdateLineaCredito } = props;
    const {user} = useLoginContext();
    const [showModal, setShowModal] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [lineaIdime, setlineaIdime] = React.useState("");
    const [validationSchema, setSchema] = React.useState(steps[activeStep].validationSchema);
    const {request, setRequest} = useRequestContext();
    const navigate = useNavigate();
    const intl = useIntl();
    
        
    
    React.useEffect(()=>{
        setSchema(steps[activeStep].validationSchema)
    },[activeStep])

    const handleNext = async (values: any) => {
        setlineaIdime(values.pws_cod_credi);
        onUpdateLineaCredito(values.pws_cod_credi);
        if (activeStep === 0) {
            try {
                    if (!request.errorConsulta) {
                        if (!request.numSol) {
                            
                            const dateSolicitud = Moment(new Date()).format('MM/DD/YYYY');
                            const result = await WLOCreditAPIService.addSolicitud({
                                Pws_Identificacion: values.Pws_Identificacion,
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                                Pws_Val_cuoini: "0",
                                Pws_Val_cuo: request.valueOfQuote,
                                Pws_Val_finan: values.Pws_monto,
                                Pws_Correo: values.Pws_Correo,
                                Pws_Apellidos1: values.Pws_Apellidos1,
                                Pws_Apellidos2: values.Pws_Apellidos2,
                                Pws_Telefono1: values.Pws_Telefono1,
                                Pws_Telefono2: values.Pws_Telefono2,
                                Pws_Nombres: values.Pws_Nombres,
                                Pws_Val_pla: values.pws_nro_cuotas,
                                Pws_fec_solicitud: dateSolicitud,
                                Pws_Tip_Estado: "1", // Valor constante 1 Simulador S
                                Pws_Tip_Perfil: user.idProfile,
                                Pws_Tip_Person: "N",
                                Pws_linea_credito:values.pws_cod_credi,
                                Pws_Periodic:values.Pws_Val_pla //Valor que pide el ws pero a nivel de UI no existe
                            });
                            if (result.payload.result === "1") {
                                const solicitudResult = await WLOCreditAPIService.addSolicitud({
                                    Pws_Identificacion: values.Pws_Identificacion,
                                    Pws_Tip_Identificacion: values.Pws_Tip_Identificacion
                                });
                                const numSolicitud = solicitudResult.payload.data.Datos_Sol.Datos_Sol[0].Pws_num_solicitud;
                                setRequest({
                                    ...request,
                                    numIdentification: values.Pws_Identificacion,
                                    typeIdentification: values.Pws_Tip_Identificacion,
                                    numSol: numSolicitud,
                                    email: values.Pws_Correo,
                                    dateSol: dateSolicitud
                                });
                                toast.success('Hemos generado una nueva solicitud',{
                                    duration:5000,
                                });
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            }else{
                                toast.error('No es posible continuar con el proceso',{
                                    duration:5000,
                                });

                            }
                        }
                        else {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                    else {
                        toast.error('No es posible validar su identidad',{
                            duration:5000,
                        });
                    }
            } catch (e) {
                toast.error('En este momento no podemos atender su solicitud',{
                    duration:5000,
                });
                setTimeout(() => {
                }, 4000);
            }


        }

        if (activeStep === 1) {
            let momento=Moment(new Date())
            let diferencia=momento.diff(Moment(values.Pws_Fec_nacime!))
            let duration=Moment.duration(diferencia)
            if(duration.years()>=18){
            try {
                const personaRequest = {
                    Pws_Identificacion: values.Pws_Identificacion,
                    Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                    Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
                    Pws_Tip_Perfil:request.searchANI?"2":request.personCygnus?.Pws_Tip_Perfil,
                    Pws_Tip_person: values.Pws_Tip_person,
                    Pws_Tip_ocupa: values.Pws_Tip_ocupa,
                    Pws_Nombres: values.Pws_Nombres!,
                    Pws_Apellidos1: values.Pws_Apellidos1! ,
                    Pws_Apellidos2: values.Pws_Apellidos2! ,
                    Pws_Telefono1: values.Pws_Telefono1,
                    Pws_Telefono2: values.Pws_Telefono2,
                    Pws_Correo: values.Pws_Correo,
                    Pws_Fec_expe:  Moment(values.Pws_Fec_expe!).format('MM/DD/YYYY') ,
                    Pws_Lugar_exp: values.Pws_Lugar_exp! ,
                    Pws_Fec_nacime:  Moment(values.Pws_Fec_nacime!).format('MM/DD/YYYY'),
                    Pws_Estado_Civil: values.Pws_Estado_Civil,
                    Pws_Direccion_res: values.Pws_Direccion_res,
                    Pws_Departamento: values.Pws_Departamento,
                    Pws_Ciudad_res: values.Pws_Ciudad_res,
                    Pws_Genero: values.Pws_Genero,
                    Pws_Estrato_per: values.Pws_Estrato_per,
                    Pws_Per_cargo: values.Pws_Per_cargo,
                    Pws_Tip_vivienda: values.Pws_Tip_vivienda,
                    Pws_Niv_estudio: values.Pws_Niv_estudio,
                    Pws_Nom_empre: values.Pws_Nom_empre,
                    Pws_fec_ingemp:  Moment(values.Pws_fec_ingemp!).format('MM/DD/YYYY'),
                    Pws_Fideliza: values.Pws_Fideliza,
                    Pws_Tip_contra: values.Pws_Tip_contra,
                    Pws_Ant_labo: values.Pws_Ant_labo,
                    Pws_Car_emp: values.Pws_Car_emp,
                    Pws_Nom_Jefedi: values.Pws_Nom_Jefedi,
                    Pws_Direc_emp: values.Pws_Direc_emp,
                    Pws_Ciud_emp: values.Pws_Ciud_emp,
                    Pws_tel_emp1: values.Pws_tel_emp1,
                    Pws_tel_emp2: values.Pws_tel_emp2,
                }
                const bancoRequest ={
                    p_identificacion: values.Pws_Identificacion,
                    p_cod_cuent: values.p_cod_cuent,
                    p_cod_tipcue: values.p_cod_tipcue ,
                    p_cod_ent: values.p_cod_ent,
                    p_cod_sucu: values.s_nombre,
                    p_cod_ciud: values.Pws_Ciudad_res,
                    p_pagaduria: "1",
                }
                        if (request?.searchANI) {
                            const result = await WLOCreditAPIService.addSolicitudPersona(personaRequest);
                            if (!_.isEmpty(result.payload)) {
                                console.log(result);
                                if (result.payload.result > 0) {
                                    //agregarDireccion(values)
                                    
                                    
                                    
                                    toast.success('Hemos generado una nueva solicitud persona',{
                                        duration:5000,
                                    });
                                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                } else {
                                    toast.error('No hemos podido wizar1 completar tu solicitud.',{
                                        duration:5000,
                                    });
                                }
                            }
                    } else {
                            const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({Pws_Identificacion: values.Pws_Identificacion,
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion});
                            if (!_.isEmpty(resultConsultaPersona.payload)) {
                                const personaConsultaResult = resultConsultaPersona.payload.data.datos_persona.Datos_persona[0];
                                const infoUpdatePerson = {...personaRequest, Pws_i_codigo: personaConsultaResult ? personaConsultaResult.Pws_i_codigo : 0};
                                const resultUpdatePersona = await WLOCreditAPIService.addSolicitudPersona(infoUpdatePerson);
                                if (resultUpdatePersona.payload.result > 0) {
                                    //agregarDireccion(values)
                                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                    //Preguntar si efectivamente se creo.
                                    toast.success('Hemos actualizado su solicitud persona',{
                                        duration:5000,
                                    });
                                } else {
                                    toast.error('No hemos podido wizar2 completar tu solicituds.',{
                                        duration:5000,
                                    });
                                }
                            }
                    }
                    const cignus=await WLOCreditAPIService.grabar_cignus({
                        Pws_Identificacion :values.Pws_Identificacion,
                        Pws_Tip_Identificacion:values.Pws_Tip_Identificacion,
                        Pws_Tip_Perfil:"2"
                    })
                    const banco = await WLOCreditAPIService.addBank(bancoRequest)
            } catch (e) {
                toast.error('En este momento no podemos atender su solicitud',{
                    duration:5000,
                });
                setTimeout(() => {
                }, 5000);
            }
        }else{
            toast.error('Debes tener al menos 18 años de edad',{
                duration:5000,
            });

        }
        }

        if (activeStep === 2) {
            try {
                const operFinancieras = {
                    s_num_solicitud: request.numSol!,
                    s_identificacion: values.Pws_Identificacion,
                    s_tipo_identificacion: values.Pws_Tip_Identificacion,
                    s_ingreso_principal: values.s_ingreso_principal,
                    s_otros_ingresos: values.s_otros_ingresos,
                    s_otros_egresos: values.s_otros_egresos,
                    s_arriendo: values.s_arriendo,
                    s_concep_otr_ingre: values.s_concep_otr_ingre,
                    s_declarante_ren: values.s_declarante_ren ? "1" : "0",
                    s_moneda_ext: values.s_moneda_ext ? "1" : "0",
                    s_monext_oper: values.s_monext_oper,
                    s_tip_monext: values.s_tip_monext,
                    s_cuent_ext: values.s_cuent_ext,
                    s_cuen_extban: values.s_cuen_extban,
                    s_cuen_extnum: values.s_cuen_extnum,
                    s_cuen_extpais: values.s_cuen_extpais,
                    s_cuen_extciudad: values.s_cuen_extciudad
                }
                const valorfinancial=await WLOCreditAPIService.addFinancialInformation(operFinancieras);
                const peps = {
                    s_num_solicitud: request.numSol!,
                    s_identificacion: values.Pws_Identificacion,
                    s_tipo_identificacion: values.Pws_Tip_Identificacion,
                    s_pep_recpublic: values.s_pep_recpublic ? "1" : "0",
                    s_pep_poderpublic: values.s_pep_poderpublic ? "1" : "0",
                    s_pep_reconpublic: values.s_pep_reconpublic ? "1" : "0",
                    s_pep_pubexpue: values.s_pep_pubexpue ? "1" : "0",
                    s_pep_seggraconsa: values.s_pep_seggraconsa ? "1" : "0",
                    s_pep_nompepseg: values.s_pep_nompepseg,
                    s_pep_paren: values.s_pep_paren,
                    s_pep_identif: values.s_pep_identif,
                }
                const result = await WLOCreditAPIService.addPeps(peps);
               //Preguntar si efectivamente se creo.
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                toast.success('Hemos añadido la información financiera a su solicitud.',{
                    duration:5000,
                });
            } catch (e) {
                toast.error('En este momento no podemos atender su solicitud',{
                    duration:5000,
                });
                setTimeout(() => {
                }, 4000);
            }
        }
        

        if (activeStep === 3) {
            if(valorCheck()){
                if (_.isEmpty(values.s_ref_nomcomple) || _.isEmpty(values.s_ref_tipo)) {
                    toast.error('Debe completar al menos una referencia para continuar',{
                        duration:5000,
                    });
                } else {
                    
                    toast.success('Su solicitud se ha creado de manera exitosa',{
                        duration:5000,
                    });
                }
            }else{
                toast.success('Debes completar los documentos Adjuntos',{
                    duration:5000,
                });

            }       
           

        }
        
    };
    const valorCheck = (): boolean => {
        let letValorAceptado = true;
        const fileInputs = document.getElementsByName("file-input");
        for (let i = 0; i < fileInputs.length; i++) {
            const valor = fileInputs[i] as HTMLInputElement;
            if (valor.files && valor.files.length === 0) {
                letValorAceptado = false; // Si algún elemento tiene 0 archivos, cambia a falso (false)
                break; // Salimos del bucle, ya que no es necesario seguir verificando
            }
        }
    
        return letValorAceptado;
    };
    
    const isLastStep = () => {
        return activeStep === steps.length - 1;
    };

    const onSubmit = async (values: any, formikBag: { setSubmitting: any; }) => {
        const {setSubmitting} = formikBag;
        if (!isLastStep()) {
            if(activeStep == 0){
                if(values.Pws_Correo==values.mail_confirmation){  
                    setSubmitting(false);
                    handleNext(values).then();
                    return;
                }else{
                    toast.error('No puede continuar los correos no coincide',{
                        duration:5000,
                    });
        
                }
            }else{
                setSubmitting(false);
                handleNext(values).then();
                return;
            }
            
        } else {
            if(valorCheck()){
                const cignus=await WLOCreditAPIService.grabar_cignus({
                    Pws_Identificacion :values.Pws_Identificacion,
                    Pws_Tip_Identificacion:values.Pws_Tip_Identificacion,
                    Pws_Tip_Perfil:"2"
                })
                if(Number(cignus.payload.result)>0){
                    const dateSolicitud = Moment(new Date()).format('MM/DD/YYYY');
                    const creaCreditos=await WLOCreditAPIService.createCredito({
                        pws_monto: values.Pws_Val_finan,
                        pws_nro_cuotas: values.pws_nro_cuotas!,
                        pws_fecha_sol: dateSolicitud,
                        pws_val_recog: "",
                        pws_fecha_prox_pag: "",
                        pws_cod_credi: values.pws_cod_credi,
                        pws_form_plazo: values.Pws_Val_pla,
                        pws_identific: values.Pws_Identificacion,
                        pws_form_periodic: values.Pws_Val_pla,
                        pws_val_tf1: "",
                        pws_fec_tf1: "",
                        pws_val_tf2: "",
                        pws_fec_tf2: "",
                        pws_val_tf3: "",
                        pws_fec_tf3: "",
                        pws_num_solicitud: request.numSol!,
                        pws_estado_credito: "S",
                        pws_estado_solicitud: "S",
                        pws_descripcion:request.numSol!,
                        pws_porcentajes_radic: ""
                    })
                    if(creaCreditos.payload.message=="SUCCESS"){
                        if(request.isRotativo){
                            const addCupos= await WLOCreditAPIService.addCupo({
                            Pws_Identificacion:values.Pws_Identificacion,
                            Pws_num_Tip_Ident:values.Pws_Tip_Identificacion,
                            Pws_Solicitud:request.numSol!
                            })
                        }
                        toast.success('Hemos finalizado con su solicitud.',{
                            duration:5000,
                        });
                        const codeudorString = localStorage.getItem('codeudor');
                    if (codeudorString) {
                    const codeudor = JSON.parse(codeudorString);
                    const personaCORequest = {
                        Pws_Identificacion: codeudor.Pws_IdentificacionCode,
                        Pws_Tip_Identificacion: codeudor.Pws_Tip_IdentificacioCode,
                        Pws_Tip_Estado: "2", 
                        Pws_Tip_Perfil:request.searchANI?"2":request.personCygnus?.Pws_Tip_Perfil,
                        Pws_Tip_person: codeudor.Pws_Tip_personCode,
                        Pws_Tip_ocupa: codeudor.Pws_Tip_ocupaCode,
                        Pws_Nombres: codeudor.s_nombreCode!,
                        Pws_Apellidos1: codeudor.Pws_Apellidos1Code! ,
                        Pws_Apellidos2: codeudor.Pws_Apellidos2Code! ,
                        Pws_Telefono1: codeudor.Pws_Telefono1Code,
                        Pws_Telefono2: codeudor.Pws_Telefono2Code,
                        Pws_Correo: codeudor.Pws_CorreoCode,
                        Pws_Genero: codeudor.Pws_GeneroCode,
                        Pws_Fec_expe:  Moment(codeudor.Pws_Fec_expeCode!).format('MM/DD/YYYY') ,
                        Pws_Lugar_exp: codeudor.Pws_Lugar_expCode! ,
                        Pws_Fec_nacime:  Moment(codeudor.Pws_Fec_nacimeCode!).format('MM/DD/YYYY'),
                        Pws_Estado_Civil: codeudor.Pws_Estado_CivilCode,
                        Pws_Direccion_res: codeudor.Pws_Direccion_resCode,
                        Pws_Departamento: codeudor.Pws_DepartamentoCode,
                        Pws_Ciudad_res: codeudor.Pws_Ciudad_resCode,
                        Pws_Estrato_per: codeudor.Pws_Estrato_perCode,
                        Pws_Per_cargo: codeudor.Pws_Per_cargoCode,
                        Pws_Tip_vivienda: codeudor.Pws_Tip_viviendaCode,
                        Pws_Niv_estudio: codeudor.Pws_Niv_estudioCode,
                        Pws_Nom_empre: codeudor.Pws_Nom_empreCode,
                        Pws_fec_ingemp:  Moment(codeudor.Pws_fec_ingempCode!).format('MM/DD/YYYY'),
                        Pws_Fideliza: codeudor.Pws_FidelizaCode,
                        Pws_Tip_contra: codeudor.Pws_Tip_contraCode,
                        Pws_Ant_labo: codeudor.Pws_Ant_laboCode,
                        Pws_Car_emp: codeudor.Pws_Car_empCode,
                        Pws_Nom_Jefedi: codeudor.Pws_Nom_JefediCode,
                        Pws_Direc_emp: codeudor.Pws_Direc_empCode,
                        Pws_Ciud_emp: codeudor.Pws_Ciud_empCode,
                        Pws_tel_emp1: codeudor.Pws_tel_emp1Code,
                        Pws_tel_emp2: codeudor.Pws_tel_emp2Code,
                    }
                    const result = await WLOCreditAPIService.addSolicitudPersona(personaCORequest);
                            const operFinancierasCO = {
                                s_num_solicitud: request.numSol!,
                                s_identificacion: codeudor.Pws_IdentificacionCode,
                                s_tipo_identificacion: codeudor.Pws_Tip_IdentificacioCode,
                                s_ingreso_principal: codeudor.s_ingreso_principalCode,
                                s_otros_ingresos: codeudor.s_otros_ingresosCode,
                                s_otros_egresos: codeudor.s_otros_egresosCode,
                                s_arriendo: codeudor.s_arriendoCode,
                                s_concep_otr_ingre: codeudor.s_concep_otr_ingreCode,
                                s_declarante_ren: codeudor.s_declarante_renCode ? "1" : "0",
                                s_moneda_ext: codeudor.s_moneda_extCode ? "1" : "0",
                                s_monext_oper: codeudor.s_monext_operCode,
                                s_tip_monext: codeudor.s_tip_monextCode,
                                s_cuent_ext: codeudor.s_cuent_extCode,
                                s_cuen_extban: codeudor.s_cuen_extbanCode,
                                s_cuen_extnum: codeudor.s_cuen_extnumCode,
                                s_cuen_extpais: codeudor.s_cuen_extpaisCode,
                                s_cuen_extciudad: codeudor.s_cuen_extciudadCode
                            }
                            const valorfinancialCo=await WLOCreditAPIService.addFinancialInformation(operFinancierasCO);
                            const pepsCo = {
                                s_num_solicitud: request.numSol!,
                                s_identificacion: codeudor.Pws_IdentificacionCode,
                                s_tipo_identificacion: codeudor.Pws_Tip_IdentificacioCode,
                                s_pep_recpublic: codeudor.s_pep_recpublicCode ? "1" : "0",
                                s_pep_poderpublic: codeudor.s_pep_poderpublicCode ? "1" : "0",
                                s_pep_reconpublic: codeudor.s_pep_reconpublicCode ? "1" : "0",
                                s_pep_pubexpue: codeudor.s_pep_pubexpueCode ? "1" : "0",
                                s_pep_seggraconsa: codeudor.s_pep_seggraconsaCode ? "1" : "0",
                                s_pep_nompepseg: codeudor.s_pep_nompepsegCode,
                                s_pep_paren: codeudor.s_pep_parenCode,
                                s_pep_identif: codeudor.s_pep_identifCode,
                            }
                            const resultCo = await WLOCreditAPIService.addPeps(pepsCo);
                            const cignusCo=await WLOCreditAPIService.grabar_cignus({
                                Pws_Identificacion :codeudor.Pws_IdentificacionCode,
                                Pws_Tip_Identificacion:codeudor.Pws_Tip_IdentificacionCode,
                                Pws_Tip_Perfil:"2"
                            })
                            const codeudorInfo = {
                                Pws_codigo: "",
                                Pws_num_solicitud: request.numSol!,
                                Pws_Identificacion: values.Pws_Identificacion,
                                Pws_Radic_Codeu: request.numSol!,
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                                Pws_Iden_Code: codeudor.Pws_IdentificacionCode,
                                Pws_Tip_Identificacion_Code: codeudor.Pws_Tip_IdentificacioCode ,
                                Pws_Tip_Codeu: "2",
                                Pws_Estado: "1",
                            }
                            const codeudorRequerido= await WLOCreditAPIService.addCodeudor(codeudorInfo)
                    }
                        try {
                            const emailInfo = {
                                email: String(request.email),
                                dateSolicitud: new Date().toDateString(),
                                numsol:String(request.numSol!),
                                ps_cod_lin:values.pws_cod_credi,
                                ps_tipoCliente:values.Pws_Tip_ocupa,
                                radicado:creaCreditos.payload.data.R_numRadic,
                                Ps_tip_deucode:"1",
                                rotativo:request.isRotativo
                            }
                            const resultEnvioCorreo = await WLOCreditAPIService.sendEmailFirmaInicial(emailInfo);
                            navigate(AppRoutes.CONFIRMATION,{replace: true})
                            if (resultEnvioCorreo) {
                                
                                toast.success('Se ha enviado con éxito el email con la cotización',{
                                    duration:5000,
                                });
                            }
                        } catch (error) {
                            toast.error('En este momento no podemos atender su solicitud',{
                                duration:5000,
                            });
                            setTimeout(() => {
                            }, 5000);
                        }
                    }else{
                        toast.error("Error al creCredito",{
                            duration:5000,
                        })
                    
                    }
                }else{
                    toast.error('Tienes error en el Registro cignus',{
                        duration:5000,
                    });

                }
            }else{
                toast.error('Debes completar los documentos Adjuntos',{
                    duration:5000,
                });

            }
        
        
        }
        setTimeout(() => {
            setSubmitting(false);
        }, 500);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            navigate(AppRoutes.CATALOG, {replace: true});
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };  
    // const agregarDireccion =async (valores:any) =>{
    
    //     const valorDireccion ={
    //         Pws_Tip_Consulta: "1",
    //         Pws_Identificacion: valores.Pws_Identificacion,
    //         Pws_Tip_Identificacion:  valores.Pws_Tip_Identificacion,
    //         Pws_Tipo:  request.direcciones.road,
    //         Pws_Num_Principal:  request.direcciones.mainno,
    //         Pws_Num_Secundario:  request.direcciones.secondaryno,
    //         Pws_SufijoPri: request.direcciones.suffix,
    //         Pws_SufijoSec:  request.direcciones.suffix2,
    //         Pws_NumComplementario:  request.direcciones.complementaryno,
    //         Pws_Complemento1:  request.direcciones.Complement,
    //         Pws_NumComplementario1:  request.direcciones.complementaryno2,
    //         Pws_Complemento2:  request.direcciones.Complement3,
    //         Pws_NumComplementario2:  request.direcciones.complementaryno3,
    //         Pws_Barrio:  request.direcciones.Neighborhood,
    //     }
    // const agregarDireccion= await WLOCreditAPIService.addAddress(valorDireccion)
    // if(Number(agregarDireccion.payload.data.Resultado)>0){
      
    // }
    // }
    function ColorlibStepIcon(props: StepIconProps) {
        const {active, completed, className} = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <Check/>,
            2: <AccountCircle/>,
            3: <AttachMoney/>,
            4: <UploadFileSharp/>
        };

        return (
            <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    const initialValues = steps.reduce(
        (values, {initialValues}) => ({
            ...values,
            ...initialValues
        }),
        {}
    );

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({isSubmitting, values, errors,setErrors}) =>{
                    return(
                        <>
                            <Form>
                                <Stack direction={{xs: 'column', sm: 'row'}}
                                       spacing={{xs: 1, sm: 1, md: 4}} justifyContent="space-between">
                                    <Item elevation={0}>
                                        <div className="d-none d-md-none d-lg-block d-xl-block">
                                            <Fab variant="extended" color="neutral" aria-label="add"
                                                 onClick={()=>{handleBack();setErrors({});}} style={{float:"left"}} className="hooverButtonRed">
                                                <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                                                {intl.formatMessage({id: "button_back"})}
                                            </Fab>
                                        </div>
                                        <div className="d-block d-md-block d-lg-none d-xl-none">
                                            <Fab variant="extended" color="neutral" aria-label="add" 
                                                 onClick={()=>{handleBack();setErrors({});}} className="hooverButtonRed"> 
                                                <ArrowBack className="hooverButtonRedsvg"/>
                                                {intl.formatMessage({id: "button_back"})}
                                            </Fab>
                                        </div>
                                    </Item>
                                    <Item elevation={0}>
                                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
                                            {steps.map((item) => (
                                                <Step key={item.label}>
                                                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                        <p className="step">{item.label}</p>
                                                    </StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Item>
                                    <Item elevation={0}>
                                        <div className="d-none d-md-none d-lg-block d-xl-block">
                                            {Object.keys(errors).length > 0?<>
                                                <Fab style={{paddingLeft:"25px",paddingRight:"0px"}} variant="extended" color="error" aria-label="add" disabled={isSubmitting}
                                                type="button" onClick={()=>{
                                                    errors=
                                                    toast.error("Complete la data",{
                                                        duration:5000,
                                                    });}}className="hooverButtonsubmit">
                                                {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                                <ArrowForward sx={{mr: 1}} className="hooverButtonRedsvgsubmit"/>
                                            </Fab>
                                            </>:<>
                                            <Fab style={{paddingLeft:"25px",paddingRight:"0px"}} variant="extended" color="error" aria-label="add" disabled={isSubmitting}
                                            type={activeStep == 0 ? "submit" : "button"} className="hooverButtonsubmit"
                                            onClick={() =>                                             
                                                activeStep != 0 ? setShowModal(true) : console.log(activeStep)
                                            }>
                                                {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                                <ArrowForward sx={{mr: 1}} className="hooverButtonRedsvgsubmit"/>
                                            </Fab>
                                            </>}
                                        </div>
                                    </Item>
                                </Stack>
                                <Item elevation={0}>
                                <div className="d-none d-md-none d-lg-block d-xl-block" id="btnSiguiente">
                                    {activeStep !== 3 && ( 
                                    <>
                                        {Object.keys(errors).length > 0 ? (
                                        <>
                                            <Fab
                                            style={{ paddingLeft: "25px", paddingRight: "0px" }}
                                            variant="extended"
                                            color="error"
                                            aria-label="add"
                                            disabled={isSubmitting}
                                            type="button"
                                            onClick={() => {
                                                errors = toast.error("Complete la data",{
                                                    duration:5000,
                                                });
                                            }}
                                            className="hooverButtonsubmit"
                                            >
                                            {isLastStep()
                                                ? intl.formatMessage({ id: "button_process" })
                                                : intl.formatMessage({ id: "button_next" })}
                                            <ArrowForward sx={{ mr: 1 }} className="hooverButtonRedsvgsubmit" />
                                            </Fab>
                                        </>
                                        ) : (
                                        <>
                                            <Fab
                                            style={{ paddingLeft: "25px", paddingRight: "0px" }}
                                            variant="extended"
                                            color="error"
                                            aria-label="add"
                                            disabled={isSubmitting}
                                            type={activeStep === 0 ? "submit" : "button"}
                                            className="hooverButtonsubmit"
                                            onClick={() => (activeStep !== 0 ? setShowModal(true) : console.log(activeStep))}
                                            >
                                            {isLastStep()
                                                ? intl.formatMessage({ id: "button_process" })
                                                : intl.formatMessage({ id: "button_next" })}
                                            <ArrowForward sx={{ mr: 1 }} className="hooverButtonRedsvgsubmit" />
                                            </Fab>
                                        </>
                                        )}
                                    </>
                                    )}
                                </div>
                                </Item>
                                
                                <Item elevation={0}>
                                    <div className="d-none d-md-none d-lg-block d-xl-block" id={lineaIdime == "547" ? "btnSiguiente2" : "btnSiguiente3" }>
                                        {activeStep === 3 &&( 
                                        <>
                                            {Object.keys(errors).length > 0 ? (
                                            <>
                                                <Fab
                                                style={{ paddingLeft: "25px", paddingRight: "0px" }}
                                                variant="extended"
                                                color="error"
                                                aria-label="add"
                                                disabled={isSubmitting}
                                                type="button"
                                                onClick={() => {
                                                    errors = toast.error("Complete la data",{
                                                        duration:5000,
                                                    });
                                                }}
                                                className="hooverButtonsubmit"
                                                >
                                                {isLastStep()
                                                    ? intl.formatMessage({ id: "button_process" })
                                                    : intl.formatMessage({ id: "button_next" })}
                                                <ArrowForward sx={{ mr: 1 }} className="hooverButtonRedsvgsubmit" />
                                                </Fab>
                                            </>
                                            ) : (
                                            <>
                                                <Fab
                                                style={{ paddingLeft: "25px", paddingRight: "0px" }}
                                                variant="extended"
                                                color="error"
                                                aria-label="add"
                                                disabled={isSubmitting}
                                                type= "button"
                                                className="hooverButtonsubmit"
                                                onClick={() => (setShowModal(true))}>
                                                {isLastStep()
                                                    ? intl.formatMessage({ id: "button_process" })
                                                    : intl.formatMessage({ id: "button_next" })}
                                                <ArrowForward sx={{ mr: 1 }} className="hooverButtonRedsvgsubmit" />
                                                </Fab>
                                            </>
                                            )}
                                        </>
                                        )}
                                        </div>
                                </Item>
                                {showModal && (<ModalReConfirm show={showModal} onHide={() => setShowModal(false)}/>)}
                                <RenderContainer errors={errors} values={values} step={steps[activeStep].key as any} setPopup={setPopup}/>
                                <Item elevation={0}>
                                    
                                    <div className="d-block d-md-block d-lg-none d-xl-none">
                                    {Object.keys(errors).length > 0?<>
                                        <Fab variant="extended" style={{paddingLeft:"25px",paddingRight:"0px"}} color="error" aria-label="add" disabled={isSubmitting}
                                            type="button" onClick={()=>{toast.error("Complete la data",{
                                                duration:5000,
                                            });console.log(errors)}} className="hooverButtonsubmit">
                                            {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                            <ArrowForward sx={{mr: 1}} className="hooverButtonRedsvgsubmit"/>
                                        </Fab>
                                            </>:<>
                                            <Fab style={{paddingLeft:"25px",paddingRight:"0px"}} variant="extended" color="error" aria-label="add" disabled={isSubmitting}
                                            type="submit" className="hooverButtonsubmit">
                                            {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                            <ArrowForward sx={{mr: 1}} className="hooverButtonRedsvgsubmit"/>
                                        </Fab>
                                            </>}
                                    </div>
                                </Item>
                            </Form>
                        </>
                    )
                } }
            </Formik>
        </div>
        
    );
    
}