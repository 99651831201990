import React,{useEffect, useState} from 'react';
import '../ConfirmInfo/ci.scss';
import {useNavigate,useLocation} from 'react-router-dom';
import { ArrowForward,ArrowBack,Edit} from '@mui/icons-material';
import {typeIdentification,civilStatus,estractos,typeVivienda,nivelEstudios,typeContract,cities,Ocupaciones} from '../../../__mocks__/data';
import {AppRoutes} from "../../../Router";
import Fab from '@mui/material/Fab';
import { WLOCreditAPIService ,AdminService} from '../../../services';
import toast from 'react-hot-toast';
import { Field,Form, Formik } from "formik";
import _ from "lodash";
import {useIntl} from "react-intl";
import Moment from 'moment';
import App from '../../../App';
import { useRequestContext } from '../../../hooks/useRequestContext';
import { WloCreditoService } from '../../../types';
export const BoxCoInfo = (valorPersonal:any) => {
    const {person,update} = valorPersonal.valorPersonal;
    const {request, setRequest} = useRequestContext();
    const [NoReadCedula, setReadCedula] = React.useState(true);
  const intl = useIntl();
  
  const [one] =React.useState(false)
  const [ciudades, setCiudades] = React.useState(cities as any);
  const [ciudades2, setCiudades2] = React.useState(cities as any);
  const [departamentos, setDepartamentos] = React.useState(cities as any)
 
  const [allCities, setAllCiudades] = React.useState(cities as any);
  const [EstadosCivil, setEstadosCivil] = useState([]);
  const [tipovivi, setTipoVivienda] = useState([]);
  const [NivelEsti, setNivelEstudio] = useState([]);
  const [TipoContrato, setAllTipoContrato] = useState([]);
  const [allOcupaciones, SetAllOcupaciones] = useState([]);
  useEffect(()=>{
      AdminService.getAllCiudades().then((Datos:any)=>{
          setAllCiudades(Datos.payload.data)
      })
      getAlldata()
  },[one])
  const getAlldata=async ()=>{
    const estadosCvil= await AdminService.getEstadosCiviles();
    if(estadosCvil.payload.data.listEstCivil.Datos_EstCivil.length>0){
        setEstadosCivil(estadosCvil.payload.data.listEstCivil.Datos_EstCivil)
    }
    const tipovivienda= await AdminService.gettipovivienda();
    if(tipovivienda.payload.data.tip_viv.datos_tip_vivi.length>0){
        setTipoVivienda(tipovivienda.payload.data.tip_viv.datos_tip_vivi)
    }
    const nivelEstudi= await AdminService.getNivelEstudio();
    if(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio.length>0){
        setNivelEstudio(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio)
    }
    const tipocon= await AdminService.getTipoContrato();
 if(tipocon.payload.data.listTipoContr.Datos_TipoContrato.length>0){
    setAllTipoContrato(tipocon.payload.data.listTipoContr.Datos_TipoContrato)
    }
    
        
    const ocupaciones= await AdminService.getOcupaciones();
    if(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones.length>0){
        SetAllOcupaciones(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones)
    }
}
useEffect(()=>{
    if(request.direcciones){
        valorPersonal.valorPersonal.person.Pws_Direccion_res=request.direcciones.road+" "+request.direcciones.mainno+" "+request.direcciones.suffix+" "+request.direcciones.secondaryno+" "+request.direcciones.suffix2+" "+request.direcciones.complementaryno+" "+request.direcciones.Complement+" "+request.direcciones.complementaryno2+" "+request.direcciones.Complement3+" "+request.direcciones.complementaryno3+" "+request.direcciones.Neighborhood
        valorPersonal.valorPersonal.person.Pws_Direccion_res=request.direcciones.road+" "+request.direcciones.mainno+" "+request.direcciones.suffix+" "+request.direcciones.secondaryno+" "+request.direcciones.suffix2+" "+request.direcciones.complementaryno+" "+request.direcciones.Complement+" "+request.direcciones.complementaryno2+" "+request.direcciones.Complement3+" "+request.direcciones.complementaryno3+" "+request.direcciones.Neighborhood
    }
    if(request.dataModifcable){
        if(request.dataModifcable.idUser>0){
            console.log(request.dataModifcable)
            setReadCedula(false)
        }
    }
},[request,setRequest])
const handleSearch = async () => {
    if (!_.isEmpty(valorPersonal.valorPersonal.person.Pws_Tip_Identificacion!) || !_.isEmpty(valorPersonal.valorPersonal.person.Pws_Identificacion!)) {
        if (valorPersonal.valorPersonal.person.Pws_Identificacion.length < 5) {
            toast.error('Debe indicar un Número de Identificación entre 5 y 10 caracteres',{
                duration:5000,
            });
        } else {
            try {
                const person2 = await WLOCreditAPIService.consultaSolicitudCygnus({
                    Pws_Tip_Identificacion: valorPersonal.valorPersonal.person.Pws_Tip_Identificacion!,
                    Pws_Identificacion: valorPersonal.valorPersonal.person.Pws_Identificacion!
                });
                console.log(person2)
                if (!_.isEmpty(person2.payload)) {
                    if (person2.payload.result === "1") {
                        person.Pws_Apellidos1=person2.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos1
                        person.Pws_Apellidos2=person2.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos2
                        person.Pws_Tip_person=person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_person
                        person.Pws_Nombres=person2.payload.data.datos_persona.Datos_persona[0].Pws_Nombres
                        person.Pws_Telefono1=person2.payload.data.datos_persona.Datos_persona[0].Pws_Telefono1
                        person.Pws_Telefono2=person2.payload.data.datos_persona.Datos_persona[0].Pws_Telefono2
                        person.Pws_Correo=person2.payload.data.datos_persona.Datos_persona[0].Pws_Correo
                        person.Pws_Tip_ocupa=person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_ocupa
                        person.Pws_Fec_expe=person2.payload.data.datos_persona.Datos_persona[0].Pws_Fec_expe.split("-00")[0]
                        person.Pws_Lugar_exp=person2.payload.data.datos_persona.Datos_persona[0].Pws_Lugar_exp
                        person.Pws_Fec_nacime=person2.payload.data.datos_persona.Datos_persona[0].Pws_Fec_nacime.split("-00")[0]
                        person.Pws_Estado_Civil=person2.payload.data.datos_persona.Datos_persona[0].Pws_Estado_Civil
                        person.Pws_Direccion_res=person2.payload.data.datos_persona.Datos_persona[0].Pws_Direccion_res
                        person.Pws_Departamento=person2.payload.data.datos_persona.Datos_persona[0].Pws_Departamento
                        person.Pws_Ciudad_res=person2.payload.data.datos_persona.Datos_persona[0].Pws_Ciudad_res
                        person.Pws_Genero=person2.payload.data.datos_persona.Datos_persona[0].Pws_Genero
                        person.Pws_Estrato_per=person2.payload.data.datos_persona.Datos_persona[0].Pws_Estrato_per
                        person.Pws_Per_cargo=person2.payload.data.datos_persona.Datos_persona[0].Pws_Per_cargo
                        person.Pws_Tip_vivienda=person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_vivienda
                        person.Pws_Niv_estudio=person2.payload.data.datos_persona.Datos_persona[0].Pws_Niv_estudio
                        person.Pws_Nom_empre=person2.payload.data.datos_persona.Datos_persona[0].Pws_Nom_empre
                        person.Pws_fec_ingemp=person2.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp.split("-00")[0]
                        person.Pws_Fideliza=person2.payload.data.datos_persona.Datos_persona[0].Pws_Fideliza
                        person.Pws_Tip_contra=person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra
                        person.Pws_Ant_labo=person2.payload.data.datos_persona.Datos_persona[0].Pws_Ant_labo
                        person.Pws_Car_emp=person2.payload.data.datos_persona.Datos_persona[0].Pws_Car_emp
                        person.Pws_Nom_Jefedi=person2.payload.data.datos_persona.Datos_persona[0].Pws_Nom_Jefedi
                        person.Pws_Direc_emp=person2.payload.data.datos_persona.Datos_persona[0].Pws_Direc_emp
                        person.Pws_Ciud_emp=person2.payload.data.datos_persona.Datos_persona[0].Pws_Ciud_emp
                        person.Pws_tel_emp1=person2.payload.data.datos_persona.Datos_persona[0].Pws_tel_emp1
                        person.Pws_tel_emp2=person2.payload.data.datos_persona.Datos_persona[0].Pws_tel_emp2
                        setReadCedula(false)
                    } else {
                        const personANI = await WLOCreditAPIService.consultaSolicitudANI({
                            Pws_Tip_Identificacion: valorPersonal.valorPersonal.person.Pws_Tip_Identificacion!,
                            Pws_Identificacion: valorPersonal.valorPersonal.person.Pws_Identificacion!
                        });
                        person.Pws_Apellidos1=personANI.primerApellido
                        person.Pws_Apellidos2=personANI.segundoApellido
                        person.Pws_Tip_person="2"
                        person.Pws_Nombres=personANI.primerNombre+" "+personANI.segundoNombre
                        person.Pws_Telefono1=""
                        person.Pws_Telefono2=""
                        person.Pws_Correo=""
                        person.Pws_Tip_ocupa=""
                        person.Pws_Fec_expe=""
                        person.Pws_Fec_nacime=""
                        person.Pws_Estado_Civil=""
                        person.Pws_Direccion_res=""
                        person.Pws_Departamento=""
                        person.Pws_Ciudad_res=""
                        person.Pws_Genero=""
                        person.Pws_Estrato_per=""
                        person.Pws_Per_cargo=""
                        person.Pws_Tip_vivienda=""
                        person.Pws_Niv_estudio=""
                        person.Pws_Nom_empre=""
                        person.Pws_fec_ingemp=""
                        person.Pws_Fideliza=""
                        person.Pws_Tip_contra=""
                        person.Pws_Ant_labo=""
                        person.Pws_Car_emp=""
                        person.Pws_Nom_Jefedi=""
                        person.Pws_Direc_emp=""
                        person.Pws_Ciud_emp=""
                        person.Pws_tel_emp1=""
                        person.Pws_tel_emp2="" 
                        person.Pws_Lugar_exp= _.filter( ciudades.ListCiudad.Datos_Ciudad, { s_nivel: '3',s_nombre: personANI.municipioExpedicion}).length>0?_.filter( ciudades.ListCiudad.Datos_Ciudad, {s_nivel: '3', s_nombre: personANI.municipioExpedicion})[0].s_codigo:""
           
                        setReadCedula(false)
                    }
                }
            } catch (e) {
                const error = e as any;
                console.log(error)
            }
        }
    }
}

  useEffect(()=>{
      if(allCities){
       
       let citiesDepent = {
           "ListCiudad": {
               "Datos_Ciudad": [] as any
           }
       }
       
       let citiesDepent2 = {
        "ListCiudad": {
            "Datos_Ciudad": [] as any
        }
    }
       citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '2'});
       setDepartamentos(citiesDepent)

       citiesDepent2.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'});
       setCiudades2(citiesDepent2)
      }
   },[allCities])
   const handleCities = (e: { target: { value: string; }; }) => {
      let citiesDepent = {
          "ListCiudad": {
              "Datos_Ciudad": [] as any
          }
      }
      citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3', s_ubicacion: e.target.value });
      setCiudades(citiesDepent);
  }
  const cambios=(evento:any)=>{
    var dateStart= new Date(evento.target.value);
    var dateEnd=new Date();
    if(dateStart.getTime()>dateEnd.getTime()){
        toast("La fecha de ingreso no debe ser mayor a la fecha actual")
    }else{
        var one_day = 1000*60*60*24;
        let result
        result = Math.ceil(((dateEnd.getTime()-dateStart.getTime())/(one_day))/365)
        valorPersonal.valorPersonal.person.Pws_Ant_labo=result
    }
}
const cambiarUpdat =()=>{
    valorPersonal.valorPersonal.setUpdate(true)
}
    return(
        <div className="box">
                    <div className="sub-box">
                    <ul>
                  <li>
                      <label>¿Quién acredita la moto?</label>
                      {
                          update?<>
                           <Field as="select"
                                  disabled={NoReadCedula}
                                 name="Pws_Tip_person"
                                 className="form-know-you-item-input"
                                 >
                              <option value={'N'}>{'Natural'}</option>
                              <option value={'J'}>{'Juridico'}</option>
                          </Field>
                          </>:<>
                          <p>{person?(person.Pws_Tip_person==="N"?"Natural":"Juridico"):""}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Tipo de ocupación</label>
                      {
                          update?<>
                           <Field as="select"
                                  disabled={NoReadCedula}
                                 name="Pws_Tip_ocupa"
                                 className="form-know-you-item-input">
                             {allOcupaciones && allOcupaciones.map((item:any, key) => {
                            
                            return (<option key={key} value={item.s_codigo}>{item.s_descripcion}</option>)

                                    })}
                          </Field>
                          </>:<>
                          <p>
                          {Ocupaciones && Ocupaciones.map((item:any, key: number) => {
                            if(Number(item.id)===Number(person.Pws_Tip_ocupa)){
                                return (item.name)
                            }else{
                                return ("")

                            }
                                
                            })}</p>
                          </>
                      }
                    
                     
                  </li>
                  <li>
                  {valorPersonal.valorPersonal.arreglo.length>3?<>
                <div onClick={cambiarUpdat}>

<Edit/>
</div>
              </>:<>
              </>}

                  </li>
              </ul>
                </div>
            <div className="sub-box redBox">
    
                <h3>Información del solicitante</h3>
                <ul>
                  <li>
                      <label>Nombres</label>
                      {
                          update?<>
                       <Field id={'Pws_Nombres'}
                                 name={'Pws_Nombres'}
                                 type={"text"}
                                 className="form-know-you-item-input"
                                 disabled/>
                          </>:<> 
                          <p>{person?(person.Pws_Nombres?person.Pws_Nombres:""):""}</p>
                          </>
                      }
                     
                  </li>
                  <li>
                  <label>Apellidos</label>
                  {
                          update?<>
                       <Field id={'Pws_Apellidos1'}
                                 name={'Pws_Apellidos1'}
                                 type={"text"}
                                 className="form-know-you-item-input"
                                 disabled/>
                                 
                       <Field style={{marginTop:"5px"}} id={'Pws_Apellidos2'}
                                 name={'Pws_Apellidos2'}
                                 className="form-know-you-item-input"
                                 type={"text"}
                                 disabled/>
                          </>:<> 
                      <p>{person?(person.Pws_Apellidos1?person.Pws_Apellidos1+" "+person.Pws_Apellidos2:""):""} </p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Tipo de identificación</label>

                      {
                          update?<>
                           <Field as="select"
                                 name="Pws_Tip_Identificacion"
                                 className="form-know-you-item-input">
                                  {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key) => {
                          
                                  return (<option key={key}  value={item.s_codigo}>{item.s_descripcion}</option>)

                                          })}
                          </Field>
                          </>:<>
                          <p> {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key) => {
                              if(Number(item.s_codigo)=== Number(person.Pws_Tip_Identificacion)){
                                  return (item.s_descripcion)

                              }else{
                                  
                                  return ("")
                              }
                                          })}</p>
                          </>
                      }
                     
                  </li>
                  <li>
                      <label>Identificacion</label>
                      {
                          update?<>
                       <Field id={'Pws_Identificacion'}
                                 className="form-know-you-item-input"
                                 name={'Pws_Identificacion'}
                                 type={"text"}
                                 onBlur={handleSearch}/>
                          </>:<> 
                          <p>{person?(person.Pws_Identificacion?person.Pws_Identificacion:""):""}</p>
                          </>
                      }
                     
                  </li>
                  <li>
                      <label>Fecha de expedición</label>
                      {
                          update?<>
                          <Field id={'Pws_Fec_expe'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Fec_expe'}
                                 type={"date"}
                                 placeholder={'MM/dd/YYYY'}/>
                          </>:<> 
                          <p>{person.Pws_Fec_expe?person.Pws_Fec_expe:""} </p>
                          </>
                      }
                
                   
                  </li>
                  <li>
                      <label>Lugar de expedición</label>
                      {
                          update?<>
                          <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                             name="Pws_Lugar_exp"
                      >
                          {ciudades2 && ciudades2.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                              return (
                                  <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                              )
                          })}
                      </Field>

                          </>:<> 
                          <p>{departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                              if(item.s_codigo===person.Pws_Lugar_exp){
                                  return (item.s_nombre)

                              }else{
                                  return ("")
                              }
                          })}</p>
                          </>
                      }
                  </li>
              </ul>
              <ul>
                  <li>
                      <label>Fecha de nacimiento</label>
                      {
                          update?<>
                          <Field id={'Pws_Fec_nacime'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Fec_nacime'}
                                 type={"date"}
                                 placeholder={'MM/dd/YYYY'}/>
                          </>:<> 
                      <p>{person.Pws_Fec_nacime?person.Pws_Fec_nacime:""}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Estado civil</label>
                      {
                          update?<>
                           <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name="Pws_Estado_Civil">
                                  {EstadosCivil && EstadosCivil.map((item:any, key) => {
                          
                                  return (<option value={item.s_codigo}>{item.s_descripcion}</option>)

                                          })}
                          </Field>
                          </>:<>
                          <p>{EstadosCivil && EstadosCivil.map((item:any, key: number) => {
                          if(Number(item.s_codigo)===Number(person.Pws_Estado_Civil)){
                              return (item.s_descripcion)
                          }else{
                              return ("")

                          }
                              
                          })} </p>
                          </>
                      }
                    
                  </li>
                  <li>
                      <label>Dirección residencia</label>
                      {
                          update?<>
                          <Field id={'Pws_Direccion_res'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Direccion_res'}
                                 onFocus={()=>{
                                    setRequest({
                                        ...request,
                                        valoresDireccion: {identificacion:person.Pws_Identificacion,
                                            tipoindentificacion:person.Pws_Tip_Identificacion}
                                    })
                                    valorPersonal.valorPersonal.setPopup("open")
                                }}
                                readOnly='readOnly'
                                 />
                          </>:<> 
                          <p>{person?person.Pws_Direccion_res:""}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Departamentos</label>
                      {
                          update?<>
                          <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                             name="Pws_Departamento"
                              onClick={handleCities}
                      >
                          {departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                              return (
                                  <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                              )
                          })}
                      </Field>

                          </>:<> 
                          <p>{departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                              if(item.s_codigo===person.Pws_Departamento){
                                  return (item.s_nombre)

                              }else{
                                  return ("")
                              }
                          })}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Ciudad</label>
                      {
                          update?<>
                          <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                             name="Pws_Ciudad_res"
                      >
                          {ciudades && ciudades.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                              return (
                                  <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                              )
                          })}
                      </Field>

                          </>:<> 
                          <p>{allCities.ListCiudad.Datos_Ciudad && allCities.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                              if(Number(item.s_codigo)===Number(person.Pws_Ciudad_res)){
                                  return (item.s_nombre)

                              }else{
                                  return ("")
                              }
                          })}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Teléfono móvil 1</label>
                      {
                          update?<>
                          <Field id={'Pws_Telefono1'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Telefono1'}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_Telefono1:""} </p>
                          </>
                      }
                  </li>
              </ul>
              <ul>
                  <li>
                      <label>Teléfono móvil 2</label>
                  {
                          update?<>
                          <Field id={'Pws_Telefono2'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Telefono2'}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_Telefono2:""}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Email</label>
                      {
                          update?<>
                          <Field id={'Pws_Correo'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Correo'}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_Correo:""} </p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Género</label>
                      {
                          update?<>
                        <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                             name="Pws_Genero">
                          <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                          <option value={'F'}>{'Femenino'}</option>
                          <option value={'M'}>{'Masculino'}</option>
                      </Field>
                          </>:<> 
                      <p>{person?(person.Pws_Genero==="F"?"Femenino":"Masculino"):""}</p>
                          </>
                      }
                      
                  </li>
                  <li>
                      <label>Estrato social</label>
                      {
                          update?<>
                           <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name="Pws_Estrato_per">
                                  {estractos && estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map((item, key) => {
                          
                                  return (<option value={item.i_codigo}>{item.c_descripcion}</option>)

                                          })}
                          </Field>
                          </>:<>
                          <p> {estractos && estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map((item, key: number) => {
                              if(Number(item.i_codigo)===Number(person.Pws_Estrato_per)){
                                  return (item.c_descripcion)
                              }else{
                                  return ("")
  
                              }
                          })} </p>
                          </>
                      }
                    
                  </li>
                  <li>
                      <label>N° de personas a cargo</label>
                      {
                          update?<>
                          <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                             name="Pws_Per_cargo">
                          <option value={'0'}>{'0'}</option>
                          <option value={'1'}>{'1'}</option>
                          <option value={'2'}>{'2'}</option>
                          <option value={'3'}>{'3'}</option>
                          <option value={'4'}>{'4'}</option>
                          <option value={'5'}>{'5'}</option>
                          <option value={'6'}>{'6'}</option>
                          <option value={'7'}>{'7'}</option>
                          <option value={'8'}>{'8'}</option>
                          <option value={'9'}>{'9'}</option>
                          <option value={'10'}>{'10'}</option>
                      </Field>
                          </>:<> 
                      <p>{person?person.Pws_Per_cargo:""}</p>
                          </>
                      }
                      
                  </li>
              </ul>
              <ul>
                  <li>
                      <label>Tipo de vivienda </label>
                      {
                          update?<>
                           <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name="Pws_Tip_vivienda">
                                  {tipovivi && tipovivi.map((item:any, key) => {
                          
                                  return (<option value={item.i_codigo}>{item.c_descripcion}</option>)

                                          })}
                          </Field>
                          </>:<>
                          <p>{tipovivi && tipovivi.map((item:any, key: number) => {
                               if(Number(item.i_codigo)===Number(person.Pws_Tip_vivienda)){
                                  return (item.c_descripcion)
                              }else{
                                  return ("")
  
                              }
                          })}  </p>
                          </>
                      }
                      
                  </li>
                  <li>
                      <label>Nivel de estudios </label>
                      {
                          update?<>
                           <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name="Pws_Niv_estudio">
                                  {NivelEsti && NivelEsti.map((item:any, key) => {
                          
                                  return (<option value={item.s_codigo}>{item.s_descripcion}</option>)

                                          })}
                          </Field>
                          </>:<>
                          <p> {NivelEsti && NivelEsti.map((item:any, key: number) => {
                           if(Number(item.s_codigo)===Number(person.Pws_Niv_estudio)){
                              return (item.s_descripcion)
                          }else{
                              return ("")

                          }
                      })}</p>
                          </>
                      }
                     
                  </li>
              </ul>
            </div>
            <div className="sub-box redBox">
                <h3>Actividad del solicitante</h3>
                <ul>
                  <li>
                      <label>Nombre de la Compañia</label>
                      {
                          update?<>
                           <Field id={'Pws_Nom_empre'}
                                 className="form-know-you-item-input"
                                 name={'Pws_Nom_empre'}
                                 disabled={NoReadCedula}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_Nom_empre:""}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Fecha de ingreso</label>
                      {
                          update?<>
                          <Field id={'Pws_fec_ingemp'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_fec_ingemp'}
                                 type={"date"}
                                 onBlur={cambios}
                                 placeholder={'MM/dd/YYYY'}/>
                          </>:<> 
                      <p>{person.Pws_fec_ingemp?new Date(person.Pws_fec_ingemp).toLocaleDateString():""}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Tipo de contrato</label>
                      {
                          update?<>
                           <Field as="select"
                                  disabled={NoReadCedula}
                                  className="form-know-you-item-input"
                                 name="Pws_Tip_contra">
                                  {TipoContrato && TipoContrato.map((item:any, key) => {
                          
                                  return (<option value={item.s_codigo}>{item.s_descripcion}</option>)

                                          })}
                          </Field>
                          </>:<>
                          <p>{TipoContrato && TipoContrato.map((item:any, key: number) => {
                              if(Number(item.s_codigo)===Number(person.Pws_Tip_contra)){
                                  return (item.s_descripcion)
                              }else{
                                  return ("")
  
                              }
                          })} </p>
                          </>
                      }
                    
                  </li>
                  <li>
                      <label>Antigüedad laboral (años)</label>
                      {
                          update?<>
                           <Field id={'Pws_Ant_labo'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Ant_labo'}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_Ant_labo:""} año </p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Cargo que desempeña</label> 
                      {
                          update?<>
                           <Field id={'Pws_Car_emp'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Car_emp'}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_Car_emp:""}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Jefe directo</label>
                      {
                          update?<>
                           <Field id={'Pws_Nom_Jefedi'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Nom_Jefedi'}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_Nom_Jefedi:""}</p>
                          </>
                      }
                  </li>
              </ul>
              <ul>
                  <li>
                      <label>Dirección de la empresa</label>
                      {
                          update?<>
                           <Field id={'Pws_Direc_emp'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_Direc_emp'}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_Direc_emp:""} </p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Ciudad</label>
                      {
                          update?<>
                          <Field as="select"
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                             name="Pws_Ciud_emp"
                      >
                          {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'}) && _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'}).map((item:any, key: number) => {
                              return (
                                  <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                              )
                          })}
                      </Field>

                          </>:<> 
                          <p>{ _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'}).map((item:any, key: number) => {
                              if(item.s_codigo===person.Pws_Ciud_emp){
                                  return (item.s_nombre)

                              }else{
                                  return ("")
                              }
                          })}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Teléfono 1</label> 
                      {
                          update?<>
                           <Field id={'Pws_tel_emp1'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_tel_emp1'}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_tel_emp1:""}</p>
                          </>
                      }
                  </li>
                  <li>
                      <label>Teléfono 2</label>
                      {
                          update?<>
                           <Field id={'Pws_tel_emp2'}
                                 className="form-know-you-item-input"
                                  disabled={NoReadCedula}
                                 name={'Pws_tel_emp2'}
                                 />
                          </>:<> 
                      <p>{person?person.Pws_tel_emp2:""} </p>
                          </>
                      }
                  </li>
              </ul>
            </div>
            {/* <div className="submit">
                <button>Revisado</button>
            </div> */}
        </div>
    );
}

interface RenderContainerProps {
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}

export const CoInfo = (props:RenderContainerProps) => {  
    const {setPopup} = props;
  const navigate = useNavigate();
  const buscar = useLocation();
  const [codigoCodeudor, setCodigoCodeudor] = React.useState({});
  const [persona, DatoPersona] = React.useState({});
  const [one] = React.useState({}); 
  const {request, setRequest} = useRequestContext();
  const [isupdate, setUpdate] = React.useState(true);
  const [arregloValor, valorArreglo] = React.useState([] as any);
  useEffect(()=>{
    if(buscar){
      var valorBusqueda=buscar.search.replace("?","")
     let arreglo=String(valorBusqueda).split("-")
     valorArreglo(arreglo)
     if(arreglo.length==3){
        let codeudors:any={
            Pws_codigo: "",
            Pws_num_solicitud: arreglo[0],
            Pws_Identificacion: arreglo[1],
            Pws_Radic_Codeu: arreglo[0],
            Pws_Tip_Identificacion: arreglo[2],
            Pws_Iden_Code: "",
            Pws_Tip_Identificacion_Code: "",
            Pws_Tip_Codeu: ""
        }
        setRequest({
            ...request,
            dataModifcable: {}
        })
        WLOCreditAPIService.addCodeudor(codeudors).then((GetCodeudors)=>{
            if(Number(GetCodeudors.payload.result)>0){
            }
         })
         setCodigoCodeudor(0)
     }else if (arreglo.length>3){
        let codeudors:any={
            Pws_codigo: "",
            Pws_num_solicitud: arreglo[0],
            Pws_Identificacion: arreglo[1],
            Pws_Radic_Codeu: arreglo[0],
            Pws_Tip_Identificacion: arreglo[2],
            Pws_Iden_Code: "",
            Pws_Tip_Identificacion_Code: "",
            Pws_Tip_Codeu: ""
        }
        WLOCreditAPIService.addCodeudor(codeudors).then((GetCodeudors)=>{
            if(Number(GetCodeudors.payload.result)>0){
                let paso=false
                let cedula=""
                let type=""
                GetCodeudors.payload.data.datos_codeu.Datos_codeudor.forEach((element:any) => {
                    if(Number(element.Pws_codigo)==Number(arreglo[3])){
                        paso=true
                        cedula=element.Pws_Iden_Code
                        type=element.Pws_Tip_Identificacion_Code
                    }
                });
                if(paso){
                    putDatainView(cedula,type,arreglo)
                }else{
                    toast.error("No es el codeudor propetario",{
                        duration:5000,
                    })
                }
            }
         })
        setCodigoCodeudor(arreglo[3])
        
     }
    }
    },[buscar,navigate])

    const putDatainView =(cedulaSearhc:any,typeCedul:any,arreglo:any)=>{
        setUpdate(false)
        let sends:WloCreditoService.SolicitudDetalla={
            Pws_Num_Solicitud:arreglo[0],
            Pws_Identificacion:cedulaSearhc,
            Pws_Tip_Identificacion:typeCedul,
            Pws_Perfil_Acceso:"",
            Pws_Fec_Solicitud:"",
        }
        WLOCreditAPIService.consultaSolicitudCygnusDetal(sends).then((valor)=>{
            if(Number(valor.payload.result)>0){
                if(valor.payload.data.datos_persona){
                    if(valor.payload.data.datos_persona.Datos_persona.length>0){
                                        let dataCygnus=valor.payload.data.datos_persona.Datos_persona[0]
                                        console.log(dataCygnus)
                                        const datainicial = {
                                            Pws_Identificacion: dataCygnus.Pws_Identificacion,
                                            Pws_Tip_Identificacion: dataCygnus.Pws_Tip_Identificacion,
                                            Pws_Tip_Estado: dataCygnus.Pws_Tip_Estado, // Valor constante para confirmación de información.
                                            Pws_Tip_Perfil:  dataCygnus.Pws_Tip_Perfil,
                                            Pws_Tip_person:  dataCygnus.Pws_Tip_person,
                                            Pws_Tip_ocupa:  dataCygnus.Pws_Tip_ocupa,
                                            Pws_Nombres:  dataCygnus.Pws_Nombres,
                                            Pws_Apellidos1:  dataCygnus.Pws_Apellidos1,
                                            Pws_Apellidos2:  dataCygnus.Pws_Apellidos2,
                                            Pws_Telefono1:  dataCygnus.Pws_Telefono1,
                                            Pws_Telefono2:  dataCygnus.Pws_Telefono2,
                                            Pws_Correo:  dataCygnus.Pws_Correo,
                                            Pws_Fec_expe:  dataCygnus.Pws_Fec_expe.split("-00")[0] ,
                                            Pws_Lugar_exp: dataCygnus.Pws_Lugar_exp,
                                            Pws_Fec_nacime: dataCygnus.Pws_Fec_nacime.split("-00")[0] ,
                                            Pws_Estado_Civil:  dataCygnus.Pws_Estado_Civil,
                                            Pws_Direccion_res:  dataCygnus.Pws_Direccion_res,
                                            Pws_Departamento:  dataCygnus.Pws_Departamento,
                                            Pws_Ciudad_res:  dataCygnus.Pws_Ciudad_res,
                                            Pws_Genero:  dataCygnus.Pws_Genero,
                                            Pws_Estrato_per:  dataCygnus.Pws_Estrato_per,
                                            Pws_Per_cargo:  dataCygnus.Pws_Per_cargo,
                                            Pws_Tip_vivienda: dataCygnus.Pws_Tip_vivienda,
                                            Pws_Niv_estudio:  dataCygnus.Pws_Niv_estudio,
                                            Pws_Nom_empre:  dataCygnus.Pws_Nom_empre,
                                            Pws_fec_ingemp:  dataCygnus.Pws_fec_ingemp.split("-00")[0],
                                            Pws_Fideliza: dataCygnus.Pws_Fideliza,
                                            Pws_Tip_contra:  dataCygnus.Pws_Tip_contra,
                                            Pws_Ant_labo:  dataCygnus.Pws_Ant_labo,
                                            Pws_Car_emp:  dataCygnus.Pws_Car_emp,
                                            Pws_Nom_Jefedi:  dataCygnus.Pws_Nom_Jefedi,
                                            Pws_Direc_emp: dataCygnus.Pws_Direc_emp,
                                            Pws_Ciud_emp:  dataCygnus.Pws_Ciud_emp,
                                            Pws_tel_emp1:  dataCygnus.Pws_tel_emp1,
                                            Pws_tel_emp2: dataCygnus.Pws_tel_emp2
                                      }
                                      DatoPersona(datainicial)
                                      setRequest({
                                        ...request,
                                        dataModifcable: {
                                            identificacion:dataCygnus.Pws_Identificacion,
                                            tipoindentificacion:dataCygnus.Pws_Tip_Identificacion,
                                            codeudorID:arreglo[3],
                                            idUser:dataCygnus.Pws_i_codigo
                                        }
                                        })
                    }
                }
                
             
            }
         },(error)=>{
         })
    
     }
  useEffect(()=>{
    const datainicial = {
        Pws_Identificacion: "",
        Pws_Tip_Identificacion: "",
        Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
        Pws_Tip_Perfil:  "",
        Pws_Tip_person:  "",
        Pws_Tip_ocupa:  "",
        Pws_Nombres:  "",
        Pws_Apellidos1:  "",
        Pws_Apellidos2:  "",
        Pws_Telefono1:  "",
        Pws_Telefono2:  "",
        Pws_Correo:  "",
        Pws_Fec_expe:  "" ,
        Pws_Lugar_exp: "",
        Pws_Fec_nacime: "" ,
        Pws_Estado_Civil:  "",
        Pws_Direccion_res:  "",
        Pws_Departamento:  "",
        Pws_Ciudad_res:  "",
        Pws_Genero:  "",
        Pws_Estrato_per:  "",
        Pws_Per_cargo:  "",
        Pws_Tip_vivienda: "",
        Pws_Niv_estudio:  "",
        Pws_Nom_empre:  "",
        Pws_fec_ingemp:  "",
        Pws_Fideliza:  "",
        Pws_Tip_contra:  "",
        Pws_Ant_labo:  "",
        Pws_Car_emp:  "",
        Pws_Nom_Jefedi:  "",
        Pws_Direc_emp:  "",
        Pws_Ciud_emp:  "",
        Pws_tel_emp1:  "",
        Pws_tel_emp2: "",
  }
  document.getElementsByClassName("title")[0].scrollIntoView()
  DatoPersona(datainicial)
    },[one])
    const onSubmit=async (values: any, formikBag: { setSubmitting: any; }) => {
        let message=""
        if(!values.Pws_Fec_expe)message="Fecha de Expedicion Incorrecta";
        if(!values.Pws_Fec_nacime)message="Fecha de Nacimiento Incorrecta";
        if(!values.Pws_fec_ingemp)message="Fecha de Ingreso Incorrecta";
        if(!message){
            const personaRequest = {
                Pws_Identificacion: values.Pws_Identificacion,
                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
                Pws_Tip_Perfil: "2",
                Pws_Tip_person: values.Pws_Tip_person,
                Pws_Tip_ocupa: values.Pws_Tip_ocupa,
                Pws_Nombres: values.Pws_Nombres ,
                Pws_Apellidos1: values.Pws_Apellidos1,
                Pws_Apellidos2: values.Pws_Apellidos2,
                Pws_Telefono1: values.Pws_Telefono1,
                Pws_Telefono2: values.Pws_Telefono2,
                Pws_Correo: values.Pws_Correo,
                Pws_Fec_expe: Moment(new Date(values.Pws_Fec_expe!)).format('MM/DD/YYYY') ,
                Pws_Lugar_exp: values.Pws_Lugar_exp ,
                Pws_Fec_nacime:  Moment(new Date(values.Pws_Fec_nacime!)).format('MM/DD/YYYY'),
                Pws_Estado_Civil: values.Pws_Estado_Civil,
                Pws_Direccion_res: values.Pws_Direccion_res,
                Pws_Departamento: values.Pws_Departamento,
                Pws_Ciudad_res: values.Pws_Ciudad_res,
                Pws_Genero: values.Pws_Genero,
                Pws_Estrato_per: values.Pws_Estrato_per,
                Pws_Per_cargo: values.Pws_Per_cargo,
                Pws_Tip_vivienda: values.Pws_Tip_vivienda,
                Pws_Niv_estudio: values.Pws_Niv_estudio,
                Pws_Nom_empre: values.Pws_Nom_empre,
                Pws_fec_ingemp: Moment(new Date(values.Pws_fec_ingemp!)).format('MM/DD/YYYY'),
                Pws_Fideliza: "0",
                Pws_Tip_contra: values.Pws_Tip_contra,
                Pws_Ant_labo: values.Pws_Ant_labo,
                Pws_Car_emp: values.Pws_Car_emp,
                Pws_Nom_Jefedi: values.Pws_Nom_Jefedi,
                Pws_Direc_emp: values.Pws_Direc_emp,
                Pws_Ciud_emp: values.Pws_Ciud_emp,
                Pws_tel_emp1: values.Pws_tel_emp1,
                Pws_tel_emp2: values.Pws_tel_emp2,
            }
            console.log(personaRequest)
            const resultUpdatePersona = await WLOCreditAPIService.addSolicitudPersona(personaRequest)
            console.log(resultUpdatePersona)
            if(Number(resultUpdatePersona.payload.result)>0 || Number(resultUpdatePersona.payload.result)==-4 ){
                if(Number(resultUpdatePersona.payload.result)==-4){
                    const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({Pws_Identificacion: values.Pws_Identificacion,
                        Pws_Tip_Identificacion: values.Pws_Tip_Identificacion});
                    if (!_.isEmpty(resultConsultaPersona.payload)) {
                        const personaConsultaResult = resultConsultaPersona.payload.data.datos_persona.Datos_persona[0];
                        const infoUpdatePerson = {...personaRequest, Pws_i_codigo: personaConsultaResult ? personaConsultaResult.Pws_i_codigo : 0};
                        const resultUpdatePersona = await WLOCreditAPIService.addSolicitudPersona(infoUpdatePerson);
                        console.log(infoUpdatePerson)
                        if (resultUpdatePersona.payload.result > 0) {
                            agregarCygnus(personaRequest)
                        } else {
                            toast.error('No hemos podido info1 completar tu solicitud.',{
                                duration:5000,
                            });
                        }
                    }else{
                        console.log(resultConsultaPersona)
                        toast.error('No hemos podido info2 completar tu solicitud.',{
                            duration:5000,
                        });
                    }
                }else{
                    agregarCygnus(personaRequest)
                }
               
            }else{
                toast(resultUpdatePersona.payload.message)
            }
        }else{
toast.error(message,{
    duration:5000,
})
        }
     
  }
  const agregarCygnus= async(persona:any)=>{
    const cignus=await WLOCreditAPIService.grabar_cignus({
        Pws_Identificacion :persona.Pws_Identificacion,
        Pws_Tip_Identificacion:persona.Pws_Tip_Identificacion,
        Pws_Tip_Perfil:persona.Pws_Tip_Perfil!
    })
    if(Number(cignus.payload.result)>0){
        if(arregloValor.length>3){
            navigate(-1)
            toast.error('Persona Actualizada',{
                duration:5000,
            });
            
        }else{
            AgregarCodeudor(persona)

        }
    }else{
        console.log(cignus)
        console.log({
            Pws_Identificacion :persona.Pws_Identificacion,
            Pws_Tip_Identificacion:persona.Pws_Tip_Identificacion,
            Pws_Tip_Perfil:"2"!
        })
        toast.error('Tienes error en el Registro Cygnus',{
            duration:5000,
        });
    }
  }
  const AgregarCodeudor=async (persona:any)=>{
    let dataCodeudor={
        Pws_num_solicitud: arregloValor[0],
        Pws_Identificacion: arregloValor[1],
        Pws_Radic_Codeu: arregloValor[0],
        Pws_Tip_Identificacion: arregloValor[2],
        Pws_Iden_Code: persona.Pws_Identificacion,
        Pws_Tip_Identificacion_Code: persona.Pws_Tip_Identificacion,
        Pws_Tip_Codeu:"1",
        Pws_Estado:"4"
    }
    const codeudor = await WLOCreditAPIService.addCodeudor(dataCodeudor)    
    console.log(codeudor)
    if(Number(codeudor.payload.result)>0){
        toast("Propetario registrado")
        if(arregloValor.length>3){
            navigate(-1)

            if(Number(arregloValor[3])){
                navigate(AppRoutes.DETALLE_DEL_CODEUDOR_REQUERIDO+"?"+arregloValor[0]+"-"+arregloValor[1]+"-"+ arregloValor[2])
            }else{
                navigate(AppRoutes.CONFIRMATION)

            }
        }else{
            navigate(-1)

        }
     
    }else{
        toast.error('Tienes error en el Registro Codeudor',{
            duration:5000,
        });

    }
  }
  
  return (
    
    <div className="detailCreditR" >
        <div className="header">
        <Fab variant="extended" color="neutral" aria-label="add"
                                             onClick={()=>{
                                                navigate(-1)
                                              }}
                                               style={{float:"left"}} className="hooverButtonRed">
                                            <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                                            {"Atras"}
                                        </Fab>
            <h2 className="title">Información del propietario codeudor</h2>
            {!isupdate?<></>:<>
            
            <Fab variant="extended" color="error" aria-label="add" onClick={()=>{
              document.getElementById("saveutton")?.click()
            }} className="hooverButtonsubmit">
                 <ArrowForward className="hooverButtonRedsvgsubmit" sx={{mr: 1}}/>
                {'Agregar   '}
            </Fab>
            </>}
        </div>
        <p className="">Por favor, complete la siguiente información</p>
          
        <div className="body">
            <div className="mainDetail">
            <Formik
                enableReinitialize={true} 
                initialValues={persona}
                onSubmit={onSubmit}
            >
                 {({isSubmitting, values, errors,handleSubmit}) => (
                        
                    <>
                    <Form>
                <BoxCoInfo valorPersonal={{person:values,setPopup,update:isupdate,setUpdate,arreglo:arregloValor}}/>
                <button type="submit" style={{display:"none"}} id="saveutton"></button>  
                    </Form>
                    </>)
                    }
                
            </Formik>
            </div>
        </div>
    </div>
  )
}
