import React, { useEffect, useState } from 'react';
import './dc.scss';
import { ArrowBack, Search } from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import { FUllPill } from '../Pill/Pill';
import { Doc, Person, File, RedCheck, Edit, GreenCheck, GreenCheckBig, Documentos, Documentosred } from '../../../assets/icons/component';
import { ReferenceTable, CodeudorTable } from '../../shared/ReferenceDocument/ReferenceDocument';
import { Rating } from '../RatingScore';
import { AppRoutes } from '../../../Router';
import { useNavigate, useLocation } from 'react-router-dom';
import { WLOCreditAPIService, AdminService } from '../../../services';
import { WloCreditoService } from '../../../types';
import toast from 'react-hot-toast';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { typeIdentification, estractos, cities, Ocupaciones } from '../../../__mocks__/data';
import { useRequestContext } from '../../../hooks/useRequestContext';
import { useLoginContext } from '../../../hooks/useLoginContext';
import ParseNumberThousand from '../../shared/Utilities/ParseNumberThousand';
import Moment from 'moment';
import { FormPersonalInfo } from '../../Steps/ApplicantInfo/FormPersonalInfo';
import { boolean } from 'yup/lib/locale';
import { log } from 'console';
import { Hidden } from '@mui/material';
import { Button } from 'react-bootstrap';
import { string } from 'yup';
import { ModalPagare } from './ModalPagare/ModalPagare';
import { ModalPinc } from './ModalPinc/ModalPinc';

interface infoProps {
    info: {
        bg1: string;
        text: string;
        bg2: string;
        abcilon: string;
        revisado: Boolean;
    };
    i: number;
    datodesembolso: Boolean;
}

const data = [
    {
        bg1: '#145CCC',
        text: 'Información del crédito',
        bg2: 'none',
        abcilon: '',
        revisado: true,
    },
    {
        bg1: '#4A4A4A',
        text: 'Información personal',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#4A4A4A',
        text: 'Información financiera',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#4A4A4A',
        text: 'Documentos de la solicitud',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#4A4A4A',
        text: 'Referencias',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    // {
    //     bg1: '#4A4A4A',
    //     text: 'Información codeudor Propetario',
    //     bg2: '#F13A00',
    //     abcilon: '!'
    // },
    {
        bg1: '#4A4A4A',
        text: 'Consulta tercero',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#4A4A4A',
        text: 'Información codeudor Requerido',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#4A4A4A',
        text: 'Información de verificación',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#4A4A4A',
        text: 'Calificar la Solicitud',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
];

const CreditSideBar: React.FC<infoProps> = ({ info, i, datodesembolso }) => {
    const gotobox = (event: any) => {
        document.getElementsByClassName('box')[event.currentTarget.dataset.id].scrollIntoView();
    };
    let mostrar = true;
    if (i == 8) {
        if (datodesembolso) {
            mostrar = true;
        } else {
            mostrar = false;
        }
    }
    if (mostrar) {
        return (
            <ul onClick={gotobox} data-id={i}>
                <li style={{ background: info.bg1 }} className="rank">
                    {i + 1}
                </li>
                <li className="text">{info.text}</li>
                <li style={{ background: info.bg2 }} className="abcilon">
                    {info.abcilon}
                </li>
            </ul>
        );
    } else {
        return <></>;
    }
};

export const Box1 = (dato: any) => {
    const [listCredit, setListcredit] = useState([]);
    const [one] = useState(false);
    useEffect(() => {
        getValueListCreditos();
    }, [one]);
    const getValueListCreditos = async () => {
        const valor = await AdminService.getlineascredito();
        if (valor.payload.data.ListLineaCredito.Datos_LineasCredito.length > 0) {
            setListcredit(valor.payload.data.ListLineaCredito.Datos_LineasCredito);
        }
    };
    useEffect(() => {}, [dato]);
    return (
        <div className="box" id="documentos1">
            <ul className="title">
                <li>1.</li>
                <li>Información del crédito </li>
                <Doc />
            </ul>
            <div className="sub-box">
                <ul className="w-100">
                    <li>
                        <label>Línea de crédito:</label>
                        <p>
                            {' '}
                            {listCredit.filter((valor: any) => valor.s_codigo == Number(dato.dato.Pws_linea_credito)).length > 0
                                ? listCredit.filter((valor: any) => valor.s_codigo == dato.dato.Pws_linea_credito)[0]['s_nombre']
                                : 'No tiene'}
                        </p>
                    </li>
                    <li>
                    <label>Numero de radicado:</label>
                    <p>
                        {dato.dato.Pws_num_solicitud}
                    </p>
                    </li>
                    <li>
                        <label>Monto de crédito:</label>
                        <p>
                            <NumberFormat
                                value={parseFloat(dato.dato.Pws_Val_finan)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />{' '}
                        </p>
                    </li>
                </ul>
                <ul className="w-100">
                    <li>
                        <label>Periodicidad de crédito:</label>
                        <p>{dato.dato.Pws_Periodic}</p>
                    </li>
                    <li>
                        <label>Plazo:</label>
                        <p>
                            {dato.dato.Pws_Val_pla} {dato.dato.Pws_Periodic}
                        </p>
                    </li>
                    <li>
                        <label>Valor de cuota:</label>
                        <p>
                            {' '}
                            <NumberFormat
                                value={parseFloat(dato.dato.Pws_Val_cuo)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />{' '}
                        </p>
                    </li>
                </ul>
                <ul className="submit">
                    {/* <button>Ver plan de pagos</button> */}
                    <div></div>
                    <GreenCheck />
                </ul>
            </div>
        </div>
    );
};
export const Box2 = (valorPersonal: any) => {
    const { request, setRequest } = useRequestContext();
    const [persona, DatoPersona] = React.useState({} as any);
    const [update, setUpdate] = React.useState(false);
    const [realizarAccion, setUpdate2] = React.useState(false);
    const intl = useIntl();
    const [one] = React.useState(false);
    const [ciudades, setCiudades] = React.useState(cities as any);
    const [departamentos, setDepartamentos] = React.useState(cities as any);
    const [revisado, setRevisado] = React.useState(true);
    const [allCities, setAllCiudades] = React.useState(cities as any);
    const [EstadosCivil, setEstadosCivil] = useState([]);
    const [tipovivi, setTipoVivienda] = useState([]);
    const [NivelEsti, setNivelEstudio] = useState([]);
    const [TipoContrato, setAllTipoContrato] = useState([]);
    const [revisadoCheck, setRevisadoCheck] = React.useState(false);
    const [allOcupaciones, SetAllOcupaciones] = useState([]);
    const { tipoSolicitud } = valorPersonal;
    const { datosCuentas } = valorPersonal;
    useEffect(() => {
        AdminService.getAllCiudades().then((Datos: any) => {
            setAllCiudades(Datos.payload.data);
        });
        getAlldata();
    }, [one]);
    const getAlldata = async () => {
        const estadosCvil = await AdminService.getEstadosCiviles();
        if (estadosCvil.payload.data.listEstCivil.Datos_EstCivil.length > 0) {
            setEstadosCivil(estadosCvil.payload.data.listEstCivil.Datos_EstCivil);
        }
        const tipovivienda = await AdminService.gettipovivienda();
        if (tipovivienda.payload.data.tip_viv.datos_tip_vivi.length > 0) {
            setTipoVivienda(tipovivienda.payload.data.tip_viv.datos_tip_vivi);
        }
        const nivelEstudi = await AdminService.getNivelEstudio();
        if (nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio.length > 0) {
            setNivelEstudio(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio);
        }
        const tipocon = await AdminService.getTipoContrato();
        if (tipocon.payload.data.listTipoContr.Datos_TipoContrato.length > 0) {
            setAllTipoContrato(tipocon.payload.data.listTipoContr.Datos_TipoContrato);
        }

        const ocupaciones = await AdminService.getOcupaciones();
        if (ocupaciones.payload.data.ListOcup.Datos_Ocupaciones.length > 0) {
            SetAllOcupaciones(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones);
        }
    };
    useEffect(() => {
        if (allCities) {
            let citiesDepent = {
                ListCiudad: {
                    Datos_Ciudad: [] as any,
                },
            };
            citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '2' });
            setDepartamentos(citiesDepent);
        }
    }, [allCities]);
    const handleCities = (e: { target: { value: string } }) => {
        let citiesDepent = {
            ListCiudad: {
                Datos_Ciudad: [] as any,
            },
        };
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3', s_ubicacion: e.target.value });
        setCiudades(citiesDepent);
    };
    useEffect(() => {
        const personainfo = valorPersonal.valorPersonal.person;
        const personaRequest = {
            Pws_Identificacion: personainfo.Pws_Identificacion,
            Pws_Tip_Identificacion: personainfo.Pws_Tip_Identificacion,
            Pws_Tip_Estado: personainfo.Pws_Tip_Estado, // Valor constante para confirmación de información.
            Pws_Tip_Perfil: personainfo.Pws_Tip_Perfil,
            Pws_Tip_person: personainfo.Pws_Tip_person,
            Pws_Tip_ocupa: personainfo.Pws_Tip_ocupa,
            Pws_Nombres: personainfo.Pws_Nombres,
            Pws_Apellidos1: personainfo.Pws_Apellidos1,
            Pws_Apellidos2: personainfo.Pws_Apellidos2,
            Pws_Telefono1: personainfo.Pws_Telefono1,
            Pws_Telefono2: personainfo.Pws_Telefono2,
            Pws_Correo: personainfo.Pws_Correo,
            Pws_Fec_expe: personainfo.Pws_Fec_expe ? personainfo.Pws_Fec_expe.split('-00.')[0] : '',
            Pws_Lugar_exp: personainfo.Pws_Lugar_exp,
            Pws_Fec_nacime: personainfo.Pws_Fec_nacime ? personainfo.Pws_Fec_nacime.split('-00.')[0] : '',
            Pws_Estado_Civil: personainfo.Pws_Estado_Civil,
            Pws_Direccion_res: personainfo.Pws_Direccion_res,
            Pws_Departamento: personainfo.Pws_Departamento,
            Pws_Ciudad_res: personainfo.Pws_Ciudad_res,
            Pws_Genero: personainfo.Pws_Genero,
            Pws_Estrato_per: personainfo.Pws_Estrato_per,
            Pws_Per_cargo: personainfo.Pws_Per_cargo,
            Pws_Tip_vivienda: personainfo.Pws_Tip_vivienda,
            Pws_Niv_estudio: personainfo.Pws_Niv_estudio,
            Pws_Nom_empre: personainfo.Pws_Nom_empre,
            Pws_fec_ingemp: personainfo.Pws_fec_ingemp ? personainfo.Pws_fec_ingemp.split('-00.')[0] : '',
            Pws_Fideliza: personainfo.Pws_Fideliza,
            Pws_Tip_contra: personainfo.Pws_Tip_contra,
            Pws_Ant_labo: personainfo.Pws_Ant_labo,
            Pws_Car_emp: personainfo.Pws_Car_emp,
            Pws_Nom_Jefedi: personainfo.Pws_Nom_Jefedi,
            Pws_Direc_emp: personainfo.Pws_Direc_emp,
            Pws_Ciud_emp: personainfo.Pws_Ciud_emp,
            Pws_tel_emp1: personainfo.Pws_tel_emp1,
            Pws_tel_emp2: personainfo.Pws_tel_emp2,
        };
        const infoUpdatePerson = { ...personaRequest, Pws_i_codigo: personainfo.Pws_i_codigo };
        DatoPersona(infoUpdatePerson);
    }, [valorPersonal]);
    const Actualizar_Info = async (valor: any) => {
        const resultUpdatePersona = await WLOCreditAPIService.addSolicitudPersona(valor);
        if (Number(resultUpdatePersona.payload.result) > 0) {
            agregarCygnus(valor);
        } else {
            
            toast.error('Error al Actualizar',{
                duration:5000,
            });
        }
    };
    const agregarCygnus = async (persona: any) => {
        const cignus = await WLOCreditAPIService.grabar_cignus({
            Pws_Identificacion: persona.Pws_Identificacion,
            Pws_Tip_Identificacion: persona.Pws_Tip_Identificacion,
            Pws_Tip_Perfil: persona.Pws_Tip_Perfil!,
        });
        if (Number(cignus.payload.result) > 0) {
            toast('Actualizado correctamente');
            valorPersonal.valorPersonal.Busquedatotal();
        } else {
            toast.error('Tienes error en el Registro Cygnus',{
                duration:5000,
            });
        }
    };
    const cambiarUpdat = () => {
        if (!update) {
            let citiesDepent = {
                ListCiudad: {
                    Datos_Ciudad: [] as any,
                },
            };
            citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, {
                s_nivel: '3',
                s_ubicacion: persona.Pws_Departamento,
            });
            setCiudades(citiesDepent);
            setUpdate2(true);
        }
        if (realizarAccion) {
            setUpdate2(false);
            document.getElementById('botonSubmit')?.click();
        }
        setUpdate(!update);
    };
    const onSubmit = async (values: any, formikBag: { setSubmitting: any }) => {
        Actualizar_Info(values);
    };
    useEffect(() => {
        if (tipoSolicitud === 'Aprobado' || tipoSolicitud === 'Rechazado') {
            setRevisado(false);
        }
    }, [tipoSolicitud]);

    return (
        <div className="box">
            <Formik enableReinitialize={true} initialValues={persona} onSubmit={onSubmit}>
                {({ isSubmitting, values, errors, handleSubmit }) => {
                    const cambios = (evento: any) => {
                        var dateStart = new Date(evento.target.value);
                        var dateEnd = new Date();
                        if (dateStart.getTime() > dateEnd.getTime()) {
                            toast('La fecha de ingreso no debe ser mayor a la fecha actual');
                        } else {
                            var one_day = 1000 * 60 * 60 * 24;
                            let result;
                            result = Math.ceil((dateEnd.getTime() - dateStart.getTime()) / one_day / 365);
                            values.Pws_Ant_labo = result;
                        }
                    };
                    return (
                        <>
                            <Form onSubmit={handleSubmit} id="valorFormSuc">
                                <ul className="title">
                                    <li>2.</li>
                                    <li>Información personal </li>
                                    <Person />
                                    <div onClick={cambiarUpdat}>
                                        <Edit />
                                    </div>
                                </ul>
                                <div className="sub-box">
                                    <ul className="nopadding">
                                        <li>
                                            <label>¿Quién acredita?</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" name="Pws_Tip_person" className="nopadding form-know-you-item-input">
                                                        <option value={'N'}>{'Natural'}</option>
                                                        <option value={'J'}>{'Juridico'}</option>
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? (persona.Pws_Tip_person === 'N' ? 'Natural' : 'Juridico') : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Tipo de ocupación</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" name="Pws_Tip_ocupa" className="nopadding form-know-you-item-input">
                                                        {allOcupaciones &&
                                                            allOcupaciones.map((item: any, key) => {
                                                                return <option value={item.s_codigo}>{item.s_descripcion}</option>;
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {allOcupaciones &&
                                                            allOcupaciones.map((item: any, key: number) => {
                                                                if (Number(item.s_codigo) === Number(persona.Pws_Tip_ocupa)) {
                                                                    return item.s_descripcion;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <div className="sub-box redBox">
                                    <h3>Información del solicitante</h3>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Nombres</label>
                                            {
                                                <>
                                                    <p>{persona ? (persona.Pws_Nombres ? persona.Pws_Nombres : '') : ''}</p>
                                                </>
                                            }
                                        </li>
                                        <li>
                                            <label>Apellidos</label>
                                            {
                                                <>
                                                    <p>
                                                        {persona
                                                            ? persona.Pws_Apellidos1
                                                                ? persona.Pws_Apellidos1 + ' ' + persona.Pws_Apellidos2
                                                                : ''
                                                            : ''}{' '}
                                                    </p>
                                                </>
                                            }
                                        </li>
                                        <li>
                                            <label>Tipo de identificación</label>

                                            {
                                                <>
                                                    <p>
                                                        {' '}
                                                        {typeIdentification &&
                                                            typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map(
                                                                (item, key) => {
                                                                    if (Number(item.s_codigo) === Number(persona.Pws_Tip_Identificacion)) {
                                                                        return item.s_descripcion;
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                }
                                                            )}
                                                    </p>
                                                </>
                                            }
                                        </li>
                                        <li>
                                            <label>Fecha de expedición</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Fec_expe'}
                                                        className="nopadding form-know-you-item-input"
                                                        name={'Pws_Fec_expe'}
                                                        type={'date'}
                                                        placeholder={'MM/dd/YYYY'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona.Pws_Fec_expe ? persona.Pws_Fec_expe : ''} </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Lugar de expedición</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" name="Pws_Lugar_exp" className="nopadding form-know-you-item-input">
                                                        {allCities &&
                                                            _.filter(allCities.ListCiudad.Datos_Ciudad).map((item: any, key: number) => {
                                                                return (
                                                                    <option key={key} value={item.s_codigo}>
                                                                        {item.s_nombre}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_codigo: persona.Pws_Lugar_exp })
                                                            .length > 0
                                                            ? _.filter(allCities.ListCiudad.Datos_Ciudad, {
                                                                  s_codigo: persona.Pws_Lugar_exp,
                                                              })[0].s_nombre
                                                            : ''}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Fecha de nacimiento</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Fec_nacime'}
                                                        name={'Pws_Fec_nacime'}
                                                        className="nopadding form-know-you-item-input"
                                                        type={'date'}
                                                        placeholder={'MM/dd/YYYY'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona.Pws_Fec_nacime ? persona.Pws_Fec_nacime : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Estado civil</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        name="Pws_Estado_Civil"
                                                        className="nopadding form-know-you-item-input">
                                                        {EstadosCivil &&
                                                            EstadosCivil.map((item: any, key) => {
                                                                return <option value={item.s_codigo}>{item.s_descripcion}</option>;
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {EstadosCivil &&
                                                            EstadosCivil.map((item: any, key: number) => {
                                                                if ((item.s_codigo) === (persona.Pws_Estado_Civil)) {
                                                                    return item.s_descripcion;
                                                                }
                                                            })}{' '}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Dirección residencia</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Direccion_res'}
                                                        name={'Pws_Direccion_res'}
                                                        onFocus={() => {
                                                            setRequest({
                                                                ...request,
                                                                valoresDireccion: {
                                                                    identificacion: persona.Pws_Identificacion,
                                                                    tipoindentificacion: persona.Pws_Tip_Identificacion,
                                                                },
                                                            });
                                                            valorPersonal.valorPersonal.setPopup('open');
                                                        }}
                                                        readonly="readonly"
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Direccion_res : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Departamento</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        name="Pws_Departamento"
                                                        onClick={handleCities}
                                                        className="nopadding form-know-you-item-input">
                                                        {departamentos &&
                                                            departamentos.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                                                return (
                                                                    <option key={key} value={item.s_codigo}>
                                                                        {item.s_nombre}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {departamentos &&
                                                            departamentos.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                                                if (item.s_codigo === persona.Pws_Departamento) {
                                                                    return item.s_nombre;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Ciudad</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" name="Pws_Ciudad_res" className="nopadding form-know-you-item-input">
                                                        {ciudades &&
                                                            ciudades.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                                                return (
                                                                    <option key={key} value={item.s_codigo}>
                                                                        {item.s_nombre}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {allCities.ListCiudad.Datos_Ciudad &&
                                                            allCities.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                                                if (Number(item.s_codigo) === Number(persona.Pws_Ciudad_res)) {
                                                                    return item.s_nombre;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Teléfono móvil 1</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Telefono1'}
                                                        type={'number'}
                                                        name={'Pws_Telefono1'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Telefono1 : ''} </p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Teléfono móvil 2</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Telefono2'}
                                                        type={'number'}
                                                        name={'Pws_Telefono2'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Telefono2 : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Email</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Correo'}
                                                        name={'Pws_Correo'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Correo : ''} </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Género</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" className="nopadding form-know-you-item-input" name="Pws_Genero">
                                                        <option>{intl.formatMessage({ id: 'placeholder_dropdown' })}</option>
                                                        <option value={'F'}>{'Femenino'}</option>
                                                        <option value={'M'}>{'Masculino'}</option>
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? (persona.Pws_Genero === 'F' ? 'Femenino' : 'Masculino') : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Estrato social</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        className="nopadding form-know-you-item-input"
                                                        name="Pws_Estrato_per">
                                                        {estractos &&
                                                            estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map(
                                                                (item, key) => {
                                                                    return <option value={item.i_codigo}>{item.c_descripcion}</option>;
                                                                }
                                                            )}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {' '}
                                                        {estractos &&
                                                            estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map(
                                                                (item, key: number) => {
                                                                    if (Number(item.i_codigo) === Number(persona.Pws_Estrato_per)) {
                                                                        return item.c_descripcion;
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                }
                                                            )}{' '}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>N° de personas a cargo</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" className="nopadding form-know-you-item-input" name="Pws_Per_cargo">
                                                        <option value={'0'}>{'0'}</option>
                                                        <option value={'1'}>{'1'}</option>
                                                        <option value={'2'}>{'2'}</option>
                                                        <option value={'3'}>{'3'}</option>
                                                        <option value={'4'}>{'4'}</option>
                                                        <option value={'5'}>{'5'}</option>
                                                        <option value={'6'}>{'6'}</option>
                                                        <option value={'7'}>{'7'}</option>
                                                        <option value={'8'}>{'8'}</option>
                                                        <option value={'9'}>{'9'}</option>
                                                        <option value={'10'}>{'10'}</option>
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Per_cargo : ''}</p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Tipo de vivienda </label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        className="nopadding form-know-you-item-input"
                                                        name="Pws_Tip_vivienda">
                                                        {tipovivi &&
                                                            tipovivi.map((item: any, key) => {
                                                                return <option value={item.i_codigo}>{item.c_descripcion}</option>;
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {tipovivi &&
                                                            tipovivi.map((item: any, key: number) => {
                                                                if (Number(item.i_codigo) === Number(persona.Pws_Tip_vivienda)) {
                                                                    return item.c_descripcion;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}{' '}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Nivel de estudios </label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        className="nopadding form-know-you-item-input"
                                                        name="Pws_Niv_estudio">
                                                        {NivelEsti &&
                                                            NivelEsti.map((item: any, key) => {
                                                                return <option value={item.s_codigo}>{item.s_descripcion}</option>;
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {' '}
                                                        {NivelEsti &&
                                                            NivelEsti.map((item: any, key: number) => {
                                                                if (Number(item.s_codigo) === Number(persona.Pws_Niv_estudio)) {
                                                                    return item.s_descripcion;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Tipo de cuenta</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" className="nopadding form-know-you-item-input" name="P_s_tip_cuenta">
                                                        <option value={'1'}>{'Giro'}</option>
                                                        <option value={'1'}>{'Cuenta corriente'}</option>
                                                        <option value={'2'}>{'Cuenta de Ahorros'}</option>
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>{valorPersonal.datosCuentas.P_s_tip_cuenta}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Número de cuenta</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'P_c_num_cuenta'}
                                                        type={'number'}
                                                        name={'P_c_num_cuenta'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{valorPersonal.datosCuentas.P_c_num_cuenta} </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Banco</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'P_s_nom_banco'}
                                                        type={'number'}
                                                        name={'P_s_nom_banco'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{valorPersonal.datosCuentas.P_s_nom_banco} </p>
                                                </>
                                            )}
                                        </li>
                                        
                                        
                                    </ul>
                                </div>
                                <div className="sub-box redBox">
                                    <h3>Actividad del solicitante</h3>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Actividad del solicitante</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Nom_empre'}
                                                        name={'Pws_Nom_empre'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Nom_empre : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Fecha de ingreso</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_fec_ingemp'}
                                                        name={'Pws_fec_ingemp'}
                                                        className="nopadding form-know-you-item-input"
                                                        type={'date'}
                                                        onBlur={cambios}
                                                        placeholder={'MM/dd/YYYY'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {persona.Pws_fec_ingemp
                                                            ? new Date(persona.Pws_fec_ingemp).toLocaleDateString()
                                                            : ''}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Tipo de contrato</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" className="nopadding form-know-you-item-input" name="Pws_Tip_contra">
                                                        {TipoContrato &&
                                                            TipoContrato.map((item: any, key) => {
                                                                return <option value={item.s_codigo}>{item.s_descripcion}</option>;
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {TipoContrato &&
                                                            TipoContrato.map((item: any, key: number) => {
                                                                if (Number(item.s_codigo) === Number(persona.Pws_Tip_contra)) {
                                                                    return item.s_descripcion;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}{' '}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Antigüedad laboral (años)</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Ant_labo'}
                                                        className="nopadding form-know-you-item-input"
                                                        type={'number'}
                                                        name={'Pws_Ant_labo'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Ant_labo : ''} años </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Cargo que desempeña</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Car_emp'}
                                                        name={'Pws_Car_emp'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Car_emp : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Jefe directo</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Nom_Jefedi'}
                                                        className="nopadding form-know-you-item-input"
                                                        name={'Pws_Nom_Jefedi'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Nom_Jefedi : ''}</p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Dirección de la empresa</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Direc_emp'}
                                                        className="nopadding form-know-you-item-input"
                                                        name={'Pws_Direc_emp'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Direc_emp : ''} </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Ciudad</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" className="nopadding form-know-you-item-input" name="Pws_Ciud_emp">
                                                        {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' }) &&
                                                            _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' }).map(
                                                                (item: any, key: number) => {
                                                                    return (
                                                                        <option key={key} value={item.s_codigo}>
                                                                            {item.s_nombre}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' }).map(
                                                            (item: any, key: number) => {
                                                                if (item.s_codigo === persona.Pws_Ciud_emp) {
                                                                    return item.s_nombre;
                                                                } else {
                                                                    return '';
                                                                }
                                                            }
                                                        )}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Teléfono 1</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_tel_emp1'}
                                                        className="nopadding form-know-you-item-input"
                                                        type={'number'}
                                                        name={'Pws_tel_emp1'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_tel_emp1 : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Teléfono 2</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_tel_emp2'}
                                                        type={'number'}
                                                        className="nopadding form-know-you-item-input"
                                                        name={'Pws_tel_emp2'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_tel_emp2 : ''} </p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <button type="submit" id="botonSubmit" style={{ display: 'none' }}></button>
                                <div className="submit" id="informacionpersonal" >
                                    {revisado ? (
                                        <>
                                            <button
                                                type="button"
                                                style={{ marginTop: '10px' }}
                                                onClick={() => {
                                                    valorPersonal.valorPersonal.changeDatoList(1);
                                                    setRevisado(false);
                                                }}>
                                                Revisado
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            {' '}
                                            <GreenCheck />
                                        </>
                                    )}
                                </div>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};

export const Box3 = (valorFinancie: any) => {
    const [financier, Valorfinan] = React.useState({} as any);
    const [valorperps, valorPEps] = React.useState({} as any);
    const [datosInicial, valoresIncial] = React.useState({} as any);
    const [update, setUpdate] = React.useState(false);
    const [realizarAccion, setUpdate2] = React.useState(false);
    const [revisado, setRevisado] = React.useState(true);
    useEffect(() => {
        if (valorFinancie.valorFinancie.datopeps) valorPEps(valorFinancie.valorFinancie.datopeps);
        if (valorFinancie.valorFinancie.valorinic) Valorfinan(valorFinancie.valorFinancie.valorinic);
        const operFinancieras = {
            s_num_solicitud: valorFinancie.valorFinancie.datosIngr[0],
            s_identificacion: valorFinancie.valorFinancie.datosIngr[1],
            s_tipo_identificacion: valorFinancie.valorFinancie.datosIngr[2],
            s_ingreso_principal: valorFinancie.valorFinancie.valorinic.Pws_Ingreso_Principal,
            s_otros_ingresos: valorFinancie.valorFinancie.valorinic.Pws_otros_ingresos,
            s_otros_egresos: valorFinancie.valorFinancie.valorinic.Pws_Otros_egresos,
            s_arriendo: valorFinancie.valorFinancie.valorinic.Pws_Arriendo,
            s_concep_otr_ingre: valorFinancie.valorFinancie.valorinic.Pws_Concep_otr_ingre,
            s_declarante_ren: valorFinancie.valorFinancie.valorinic.Pws_Declarante_ren == '1' ? true : false,
            s_moneda_ext: valorFinancie.valorFinancie.valorinic.Pws_Moneda_ext == '1' ? true : false,
            s_monext_oper: valorFinancie.valorFinancie.valorinic.Pws_Monext_oper,
            s_tip_monext: valorFinancie.valorFinancie.valorinic.Pws_Tip_monext,
            s_cuent_ext: valorFinancie.valorFinancie.valorinic.Pws_Cuent_ext == '1' ? true : false,
            s_cuen_extban: valorFinancie.valorFinancie.valorinic.Pws_Cuen_extban,
            s_cuen_extnum: valorFinancie.valorFinancie.valorinic.Pws_Cuen_extnum,
            s_cuen_extpais: valorFinancie.valorFinancie.valorinic.Pws_Cuen_extpais,
            s_cuen_extciudad: valorFinancie.valorFinancie.valorinic.Pws_Cuen_extciudad,
            s_pep_recpublic: valorFinancie.valorFinancie.datopeps.Pws_Pep_recpublic == '1' ? true : false,
            s_pep_poderpublic: valorFinancie.valorFinancie.datopeps.Pws_Pep_poderpublic == '1' ? true : false,
            s_pep_reconpublic: valorFinancie.valorFinancie.datopeps.Pws_Pep_Reconpublic == '1' ? true : false,
            s_pep_pubexpue: valorFinancie.valorFinancie.datopeps.Pws_Pep_pubexpue == '1' ? true : false,
            s_pep_seggraconsa: valorFinancie.valorFinancie.datopeps.Pws_Pep_seggraconsa == '1' ? true : false,
            s_pep_nompepseg: valorFinancie.valorFinancie.datopeps.Pws_Pep_nompepseg,
            s_pep_paren: valorFinancie.valorFinancie.datopeps.Pws_Pep_paren,
            s_pep_identif: valorFinancie.valorFinancie.datopeps.Pws_Pep_Identif,
        };
        valoresIncial(operFinancieras);
    }, [valorFinancie]);

    const cambiarUpdat = () => {
        if (!update) {
            setUpdate2(true);
        }
        if (realizarAccion) {
            setUpdate2(false);
            document.getElementById('botonSubmit2')?.click();
        }
        setUpdate(!update);
    };

    const onSubmit = async (values: any, formikBag: { setSubmitting: any }) => {
        const operFinancieras = {
            s_num_solicitud: values.s_num_solicitud,
            s_identificacion: values.s_identificacion,
            s_tipo_identificacion: values.s_tipo_identificacion,
            s_ingreso_principal: values.s_ingreso_principal,
            s_otros_ingresos: values.s_otros_ingresos,
            s_otros_egresos: values.s_otros_egresos,
            s_arriendo: values.s_arriendo,
            s_concep_otr_ingre: values.s_concep_otr_ingre,
            s_declarante_ren: values.s_declarante_ren ? '1' : '0',
            s_moneda_ext: values.s_moneda_ext ? '1' : '0',
            s_monext_oper: values.s_monext_oper,
            s_tip_monext: values.s_tip_monext,
            s_cuent_ext: values.s_cuent_ext ? '1' : '0',
            s_cuen_extban: values.s_cuen_extban,
            s_cuen_extnum: values.s_cuen_extnum,
            s_cuen_extpais: values.s_cuen_extpais,
            s_cuen_extciudad: values.s_cuen_extciudad,
        };
        const result2 = await WLOCreditAPIService.addFinancialInformation(operFinancieras);
        const peps = {
            s_num_solicitud: values.s_num_solicitud,
            s_identificacion: values.s_identificacion,
            s_tipo_identificacion: values.s_tipo_identificacion,
            s_pep_recpublic: values.s_pep_recpublic ? '1' : '0',
            s_pep_poderpublic: values.s_pep_poderpublic ? '1' : '0',
            s_pep_reconpublic: values.s_pep_reconpublic ? '1' : '0',
            s_pep_pubexpue: values.s_pep_pubexpue ? '1' : '0',
            s_pep_seggraconsa: values.s_pep_seggraconsa ? '1' : '0',
            s_pep_nompepseg: values.s_pep_nompepseg,
            s_pep_paren: values.s_pep_paren,
            s_pep_identif: values.s_pep_identif,
        };
        const result = await WLOCreditAPIService.addPeps(peps);
        valorFinancie.valorFinancie.Busquedatotal();
        toast('Actualizado correctamente');
    };
    const { tipoSolicitud } = valorFinancie;
    useEffect(() => {
        if (tipoSolicitud === 'Aprobado' || tipoSolicitud === 'Rechazado') {
        setRevisado(false);
        }
    }, [tipoSolicitud]);
    return (
        <div className="box">
            <Formik enableReinitialize={true} initialValues={datosInicial} onSubmit={onSubmit}>
                {({ isSubmitting, values, errors, handleSubmit }) => (
                    <>
                        <Form>
                            <ul className="title">
                                <li>3.</li>
                                <li>Información financiera </li>
                                <Doc />
                                <div onClick={cambiarUpdat}>
                                    <Edit />
                                </div>
                            </ul>
                            <div className="sub-box redBox">
                                <h3>Ingresos del solicitante</h3>
                                <ul className="nopadding">
                                    <li>
                                        <label>Ingreso principal</label>
                                        {update ? (
                                            <>
                                                <Field
                                                    id="s_ingreso_principal"
                                                    className="nopadding"
                                                    name="s_ingreso_principal"
                                                    placeholder={'prueba'}
                                                    component={ParseNumberThousand}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    <NumberFormat
                                                        value={values.s_ingreso_principal}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                    />{' '}
                                                </p>
                                            </>
                                        )}
                                    </li>
                                    <li>
                                        <label>Otros ingresos</label>
                                        {update ? (
                                            <>
                                                <Field
                                                    id="s_otros_ingresos"
                                                    className="nopadding"
                                                    name="s_otros_ingresos"
                                                    component={ParseNumberThousand}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    <NumberFormat
                                                        value={values.s_otros_ingresos}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                    />{' '}
                                                </p>
                                            </>
                                        )}
                                    </li>
                                    <li>
                                        <label>Valor egresos</label>
                                        {update ? (
                                            <>
                                                <Field
                                                    id="s_otros_egresos"
                                                    className="nopadding"
                                                    name="s_otros_egresos"
                                                    component={ParseNumberThousand}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    <NumberFormat
                                                        value={values.s_otros_egresos}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                    />{' '}
                                                </p>
                                            </>
                                        )}
                                    </li>
                                    <li>
                                        <label>Concepto de otros ingresos</label>
                                        {update ? (
                                            <>
                                                <Field
                                                    id="s_concep_otr_ingre"
                                                    className="nopadding form-know-you-item-input"
                                                    name="s_concep_otr_ingre"
                                                    type="text"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <p>{values.s_concep_otr_ingre}</p>
                                            </>
                                        )}
                                    </li>
                                </ul>
                                <h3>Propiedades del solicitante</h3>
                                <ul className="nopadding">
                                    <li>
                                        <label>Declarante de renta</label>
                                        {update ? (
                                            <>
                                                <div className="form-know-you-item-medium display-flex">
                                                    No
                                                    <Field
                                                        id={'s_declarante_ren'}
                                                        className="nopadding"
                                                        name="s_declarante_ren"
                                                        component={Switch}
                                                        type="checkbox"
                                                    />
                                                    Si
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <FUllPill
                                                    check={values.s_declarante_ren}
                                                    disabled={true}
                                                    id={'s_declarante_ren'}
                                                    name={'s_declarante_ren'}
                                                />
                                            </>
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className="sub-box redBox">
                                <h3> operaciones financieras </h3>
                                <div className="pillInfo">
                                    <p>¿Realizas operaciones en moneda extranjera?</p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-small display-flex">
                                                No
                                                <Field id={'s_moneda_ext'} name="s_moneda_ext" component={Switch} type="checkbox" />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_moneda_ext}
                                                disabled={true}
                                                id={'s_moneda_ext'}
                                                name={'s_moneda_ext'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>Posees cuentas en moneda extranjera</p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field id={'s_cuent_ext'} name="s_cuent_ext" component={Switch} type="checkbox" />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill check={values.s_cuent_ext} disabled={true} id={'s_cuent_ext'} name={'s_cuent_ext'} />
                                        </>
                                    )}
                                </div>
                                <h3>{`Información PEP. (Personas expuestas Políticamente)`}</h3>
                                <div className="pillInfo">
                                    <p>¿Por tu cargo manejas recursos públicos? </p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field id={'s_pep_recpublic'} name="s_pep_recpublic" component={Switch} type="checkbox" />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_recpublic}
                                                disabled={true}
                                                id={'s_pep_recpublic'}
                                                name={'s_pep_recpublic'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>¿Por tu cargo o actividad ejerces algún grado de poder público? </p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field
                                                    id={'s_pep_poderpublic'}
                                                    name="s_pep_poderpublic"
                                                    component={Switch}
                                                    type="checkbox"
                                                />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_poderpublic}
                                                disabled={true}
                                                id={'s_pep_poderpublic'}
                                                name={'s_pep_poderpublic'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>¿Gozas de reconocimiento público? </p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field
                                                    id={'s_pep_reconpublic'}
                                                    name="s_pep_reconpublic"
                                                    component={Switch}
                                                    type="checkbox"
                                                />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_reconpublic}
                                                disabled={true}
                                                id={'s_pep_reconpublic'}
                                                name={'s_pep_reconpublic'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>¿Eres familiar de alguna persona políticamente expuesta? </p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field id={'s_pep_pubexpue'} name="s_pep_pubexpue" component={Switch} type="checkbox" />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_pubexpue}
                                                disabled={true}
                                                id={'s_pep_pubexpue'}
                                                name={'s_pep_pubexpue'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>{`¿Tienes 2° de afinidad y 2° de consanguinidad (Padres, hermanos, esposa, primos, suegros, cuñados o concuñados) con PEP?`}</p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field
                                                    id={'s_pep_seggraconsa'}
                                                    name="s_pep_seggraconsa"
                                                    component={Switch}
                                                    type="checkbox"
                                                />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_seggraconsa}
                                                disabled={true}
                                                id={'s_pep_seggraconsa'}
                                                name={'s_pep_seggraconsa'}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <button type="submit" id="botonSubmit2" style={{ display: 'none' }}></button>
                        </Form>
                    </>
                )}
            </Formik>
            <div className="submit" id="informacionfinanciera">
                {revisado ? (
                    <>
                        <button
                            type="button"
                            style={{ marginTop: '10px' }}
                            onClick={() => {
                                valorFinancie.valorFinancie.changeDatoList(2);
                                setRevisado(false);
                            }}>
                            Revisado
                        </button>
                    </>
                ) : (
                    <>
                        {' '}
                        <GreenCheck />
                    </>
                )}
            </div>
        </div>
    );
};
export const Box4 = (valorDocuments: any) => {
    const [update, setUpdate] = React.useState(false);
    const [alldocuments, setDocuments] = React.useState([
        {
            Pws_Doc_Tipo: 1,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 2,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 3,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        // {
        //     Pws_Doc_Tipo: 4,
        //     Pws_Doc_nombre: '',
        //     Pws_Clave_doc: '',
        //     Pws_Doc_estado: '',
        //     Pws_Doc_ruta: '',
        // },
        // {
        //     Pws_Doc_Tipo: 5,
        //     Pws_Doc_nombre: '',
        //     Pws_Clave_doc: '',
        //     Pws_Doc_estado: '',
        //     Pws_Doc_ruta: '',
        // },
    ]);
    const { user, setUser } = useLoginContext();
    useEffect(() => {
        if (valorDocuments.valorDocuments.alldocuments) {
            if (valorDocuments.valorDocuments.alldocuments.length > 0) {
                let valordocumentos = [
                    {
                        Pws_Doc_Tipo: 1,
                        Pws_Doc_nombre: '',
                        Pws_Clave_doc: '',
                        Pws_Doc_estado: '',
                        Pws_Doc_ruta: '',
                    },
                    {
                        Pws_Doc_Tipo: 2,
                        Pws_Doc_nombre: '',
                        Pws_Clave_doc: '',
                        Pws_Doc_estado: '',
                        Pws_Doc_ruta: '',
                    },
                    {
                        Pws_Doc_Tipo: 3,
                        Pws_Doc_nombre: '',
                        Pws_Clave_doc: '',
                        Pws_Doc_estado: '',
                        Pws_Doc_ruta: '',
                    },
                    // {
                    //     Pws_Doc_Tipo: 4,
                    //     Pws_Doc_nombre: '',
                    //     Pws_Clave_doc: '',
                    //     Pws_Doc_estado: '',
                    //     Pws_Doc_ruta: '',
                    // },
                    // {
                    //     Pws_Doc_Tipo: 5,
                    //     Pws_Doc_nombre: '',
                    //     Pws_Clave_doc: '',
                    //     Pws_Doc_estado: '',
                    //     Pws_Doc_ruta: '',
                    // },
                ] as any;
                valorDocuments.valorDocuments.alldocuments.forEach((element: any) => {
                    if (element.Pws_Doc_estado == '1') valordocumentos[Number(element.Pws_Doc_Tipo) - 1] = element;
                });
                setDocuments(valordocumentos);
            }
        }
    }, [valorDocuments]);
    const [revisado, setRevisado] = React.useState(true);
    const cambiarUpdat = () => {
        if (update) {
            valorDocuments.valorDocuments.Busquedatotal();
        }
        setUpdate(!update);
    };

    const handleFileInputChange = (e: any) => {
        const target = e.target;
        if (target.files && target.files[0]) {
            const maxAllowedSize = 5 * 1024 * 1024;
            if (target.files[0].size > maxAllowedSize) {
                toast.error('Seleccione un archivo de tamaño máximo 5mb.',{
                    duration:5000,
                });
                target.value = '';
            }
        }
        let file: any = '';
        file = e.target.files[0];
        getBase64(file)
            .then(async (result) => {
                const document = result as string;
                const documentRequest = {
                    Pws_num_solicitud: valorDocuments.valorDocuments.valor[0],
                    Pws_Identificacion: valorDocuments.valorDocuments.valor[1],
                    Pws_Tip_Identificacion: valorDocuments.valorDocuments.valor[2],
                    Pws_Doc_Tipo: target.dataset.id,
                    Pws_Doc_nombre: file.name,
                    Pws_Doc_estado: '1',
                    Pws_Doc_ruta: '1',
                    Pws_Clave_doc: document,
                };

                (async () => {
                    const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                    if (Number(solicitud.payload.result) > 0) {
                        toast.success('Subido el Nuevo Archivo',{
                            duration:5000,
                        });
                    }
                })();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getBase64 = (file: any) => {
        return new Promise((resolve) => {
            let baseURL: string | ArrayBuffer | null = '';
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    const { tipoSolicitud } = valorDocuments;
    useEffect(() => {
        if (tipoSolicitud === 'Aprobado' || tipoSolicitud === 'Rechazado') {
        setRevisado(false);
        }
    }, [tipoSolicitud]);
    return (
        <div className="box">
            <ul className="title">
                <li>4.</li>
                <li>Documentos de la solicitud </li>
                <File />
                <div onClick={cambiarUpdat}>
                    <Edit />
                </div>
            </ul>
            <div className="documents">
                {alldocuments.map((valorDocumentss: any, index) => {
                    let tipo = '';
                    let documentss = '';
                    let pasar = true;
                    if (Number(valorDocumentss.Pws_Doc_Tipo) == 1) {
                        tipo = 'Copia de Cedula';
                        documentss = valorDocumentss.Pws_Doc_nombre;
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 2) {
                        tipo = 'Certificado Bancario';
                        documentss = valorDocumentss.Pws_Doc_nombre;
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 3) {
                        tipo = 'Soportes de Ingresos y/o Garantias';
                        documentss = valorDocumentss.Pws_Doc_nombre;
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 5) {
                        tipo = 'Listas restrictivas';
                        documentss = valorDocumentss.Pws_Doc_nombre;
                    }
                    const download = () => {
                        const link = document.createElement('a');
                        link.href = valorDocumentss.Pws_Clave_doc;
                        link.setAttribute('download', documentss);
                        link.setAttribute('target', '_blank');
                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();
                        link.remove();
                    };
                    if (pasar) {
                        return (
                            <>
                                <div
                                    key={index}
                                    className={update ? 'redBox' : documentss ? 'redBox check' : 'redBox'}
                                    onClick={(eve: any) => {
                                        if (update) {
                                            eve.currentTarget.querySelector('input')?.click();
                                        } else {
                                            if (documentss) {
                                                download();
                                            }
                                        }
                                    }}>
                                    {update ? (
                                        <>
                                            <input
                                                data-id={valorDocumentss.Pws_Doc_Tipo}
                                                type="file"
                                                name="file-input"
                                                id="file-input"
                                                className="file-input__input"
                                                accept="application/pdf, image/png, image/jpg, image/jpeg"
                                                onChange={handleFileInputChange}
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {update ? (
                                        <>
                                            <Documentos />
                                        </>
                                    ) : !documentss ? (
                                        <>
                                            <Documentos />
                                        </>
                                    ) : (
                                        <>
                                            <Documentosred />
                                        </>
                                    )}
                                    <p>
                                        {tipo}
                                        {update ? ' (Upload)' : ''}
                                    </p>
                                </div>
                            </>
                        );
                    } else {
                        return <></>;
                    }
                })}
            </div>
            <div className="submit" id="documentosolicitud">
                {revisado ? (
                    <>
                        <button
                            type="button"
                            style={{ marginTop: '10px' }}
                            onClick={() => {
                                valorDocuments.valorDocuments.changeDatoList(3);
                                setRevisado(false);
                            }}>
                            Revisado
                        </button>
                    </>
                ) : (
                    <>
                        {' '}
                        <GreenCheck />
                    </>
                )}
            </div>
        </div>
    );
};
export const Box5 = (valorReferencias: any) => {
    const [referencias, valorRefere] = React.useState([]);
    const [valuesInicial, valorInicial] = React.useState({});
    const [revisado, setRevisado] = React.useState(true);
    const { tipoSolicitud } = valorReferencias;
    useEffect(() => {
        valorRefere(valorReferencias.valorReferencias.datosrefe);
        valorInicial(valorReferencias.valorReferencias.values);
        if (tipoSolicitud === 'Aprobado' || tipoSolicitud === 'Rechazado') {
            setRevisado(false);
        }
    }, [valorReferencias]);
    const Busquedatotals = () => {
        valorReferencias.valorReferencias.Busquedatotal();
    };
    const onSubmit = async (values: any, formikBag: { setSubmitting: any }) => {
        const references: any = await WLOCreditAPIService.addReferences({
            s_num_solicitud: values.s_num_solicitud,
            s_identificacion: values.s_identificacion!,
            s_tipo_identificacion: values.s_tipo_identificacion!,
            s_ref_tipo: values.s_ref_tipo!,
            s_ref_parentes: values.s_ref_parentes!,
            s_ref_nomcomple: values.s_ref_nomcomple!,
            s_ref_telfij: values.s_ref_telfij!,
            s_ref_telcel: values.s_ref_telcel!,
        });
        if (references.payload.result === '1') {
            Busquedatotals();
            toast.success('Se agregado la referencia de manera exitosa',{
                duration:5000,
            });
        }
    };
    return (
        <Formik enableReinitialize={true} initialValues={valuesInicial} onSubmit={onSubmit}>
            {({ isSubmitting, values, errors, handleSubmit }) => (
                <>
                    <Form>
                        <div className="box">
                            <ul className="title">
                                <li>5.</li>
                                <li>Referencias </li>
                            </ul>
                            <div className="table">
                                <ReferenceTable
                                    references={{
                                        refer: referencias,
                                        Busquedatotals,
                                        values: valorReferencias.valorReferencias.datosValorRefer,
                                    }}
                                />
                            </div>

                            <div className="submit" id="referencias">
                                {revisado ? (
                                    <>
                                        <button
                                            type="button"
                                            style={{ marginTop: '10px' }}
                                            onClick={() => {
                                                valorReferencias.valorReferencias.changeDatoList(4);
                                                setRevisado(false);
                                            }}>
                                            Revisado
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        <GreenCheck />
                                    </>
                                )}
                            </div>
                        </div>
                        <button type="submit" style={{ display: 'none' }} id="boton_referencias"></button>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export const Box8 = (dato: any) => {
    const [revisado, setRevisado] = React.useState(true);
    const { tipoSolicitud } = dato;
    const { user, setUser } = useLoginContext();
    useEffect(() => {
        if (tipoSolicitud === 'Aprobado' || tipoSolicitud === 'Rechazado') {
            setRevisado(false);
        }
    }, [tipoSolicitud]);
    return (
        <div className="box">
            <ul className="title">
                <li>8.</li>
                <li>Información de verificación </li>
                <RedCheck />
            </ul>
            <div className="sub-box">
                <ul>
                    <div className="mapping">
                        <div className="check">
                            <GreenCheckBig />
                            <p>Aprobado</p>
                            {dato.dato.infoRetorno.listaRestrictiva ? (
                                <></>
                            ) : (
                                <>
                                    <h6>Listas restrictivas</h6>
                                </>
                            )}
                            {dato.dato.infoRetorno.DataCredito ? (
                                <></>
                            ) : (
                                <>
                                    <h6>Data Credito</h6>
                                </>
                            )}
                            {/* {dato.dato.infoRetorno.sagrilaft ? (
                                <></>
                            ) : (
                                <>
                                    <h6>Sagrilaft</h6>
                                </>
                            )} */}
                        </div>
                        <div className="score">
                            <Rating valor={dato.dato.infoRetorno.score} />
                        </div>
                    </div>
                </ul>
                <ul>
                    <div className="redic">
                        <p className="estadoAceptado">{dato.dato.infoRetorno.estado}</p>
                    </div>
                </ul>
                <div className="submit" id="consultatercero">
                    {revisado ? (
                        <>
                            <button
                                type="button"
                                style={{ marginTop: '10px' }}
                                onClick={() => {
                                    setRevisado(false);
                                    dato.dato.changeDatoList(6, 7);
                                   
                                }}>
                                Revisado
                            </button>
                        </>
                    ) : (
                        <>
                            {' '}
                            <GreenCheck />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export const Box9 = (valor: any ) => {
    const [activebutton1, setbutton1] = React.useState(false);
    const [activebutton2, setbutton2] = React.useState(false);
    const [showModal, setShowModal] = useState(false);
    const [cupoConsulta, setCupoconsulta] = React.useState('');
    const [isLoadingEmail, setIsEmail] = useState(false);
    const [isLoadingSimulator, setIsSimulator] = useState(false);
    const [detalleSol, setdetalleSol] = React.useState([] as any);
    const [selectedOption, setSelectedOption] = useState('');
    const [observacion, setObservacion] = useState('');
    const [options, setOptions] = useState<{ value: string; label: string; }[]>([]);
    const [gestor, setGestor] = useState('');
    const [estado, setEstado] = useState((valor.valor.infoRetorno.estado == "Aprobado"));
    const [pagareGenerado, setPagareGenerado] = useState(false);
    const [modalConfirmPinc, setModalConfirmPinc] = useState(false);
    useEffect(() => {
        setCupoconsulta(valor.valor.Cupoconsulta);
        setdetalleSol(valor.valor.infoCredito);
        const codigoGestor= localStorage.getItem('Gestor')!;
            setGestor(codigoGestor);
    },[gestor]);

   

    const cambiarAprobado = async () => {
        const valores = await WLOCreditAPIService.estadocredito({
            pws_identificacion: valor.valor.valor[1],
            pws_tip_identificacion: valor.valor.valor[2],
            pws_num_solicitud: valor.valor.valor[0],
            pws_estado: '1',
            pws_observacion: observacion,
            pws_gestor: "",
        });
        if (Number(valores.payload.message) >= 1) {
            toast.success('Guardado Exitosamente',{
                duration:5000,
            });
        }
    };
    const handleSendEmail = async (respuesta: boolean) => {
        try {
            const emailInfo = {
                solicitud: detalleSol.Pws_num_solicitud,
                dateSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
                email: detalleSol.Pws_Correo,
                respuesta,
            };
            const resultEnvioCorreo = await WLOCreditAPIService.confirmacionEmail(emailInfo);
            if (resultEnvioCorreo) {
                toast.success('Se ha enviado con exito el email con la confirmación',{
                    duration:5000,
                });
                setIsEmail(false);
            }
        } catch (error) {
            setIsSimulator(true);
            toast.error('En este momento no podemos atender su solicitud',{
                duration:5000,
            });
            setTimeout(() => {
                setIsSimulator(false);
            }, 5000);
        }
    };
    const sendinfo = async () => {
        var date = new Date();
        var dateTime = date.getFullYear() + '/' + date.getDay() + '/' + (date.getMonth() + 1);

        //creacionAvanceRot
        const avanceRot = {
            ps_ident_cli: valor.valor.infoCredito.Pws_Identificacion,
            ps_linea_cred: valor.valor.infoCredito.Pws_linea_credito,
            pn_monto: valor.valor.infoCredito.Pws_Val_finan,
            pn_plazo: valor.valor.infoCredito.Pws_Val_pla,
            pn_for_pla: '1',
            pn_for_pag: '1',
            pn_periodic: '1',
            pf_fecha_sol: dateTime,
            ps_estado: 'A',
        };

        let response;
        if (parseInt(valor.valor.infoCredito.Pws_Val_finan) <= parseInt(valor.valor.Cupoconsulta)) {
            avanceRot.pn_monto = valor.valor.infoCredito.Pws_Val_finan;
            response = await WLOCreditAPIService.avanceRot(avanceRot);
            if (response.payload.message == '1') {
                toast(response.payload.result);
                cambiarEstado('10');
            } else {
                toast.error(response.payload.result,{
                    duration:5000,
                });
            }
        } else {
            toast('Se informa que el avance es superior al cupo a asignar',{
                duration:5000,
            });
            //avanceRot.pn_monto  = valor.valor.Cupoconsulta
            //response  = await WLOCreditAPIService.avanceRot(avanceRot)
        }
    };
    const cambiar = async () => {
        const personaRequest = {
            Pws_ident_vende: '',
            Pws_num_solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Identificacion,
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Tip_Perfil: detalleSol.Pws_Tip_Perfil,
            Pws_linea_credito: detalleSol.Pws_linea_credito,
            Pws_Periodic: detalleSol.Pws_Periodic,
            Pws_Tip_person: detalleSol.Pws_Tip_Person,
            Pws_Fideliza: detalleSol.Pws_Fideliza,
            Pws_Tip_Estado: '2',
            Pws_fec_solicitud: detalleSol.Pws_fec_solicitud,
            Pws_Nombres: detalleSol.Pws_NombdetalleSol.Pws_Correores,
            Pws_Apellidos1: detalleSol.Pws_Apellidos1,
            Pws_Apellidos2: detalleSol.Pws_Apellidos2,
            Pws_Telefono1: detalleSol.Pws_Telefono1,
            Pws_Telefono2: detalleSol.Pws_Telefono2,
            Pws_Correo:detalleSol.Pws_Correo ,
            Pws_Val_cuoini: detalleSol.Pws_Val_cuoini,
            Pws_Val_pla: detalleSol.Pws_Val_pla,
            Pws_Val_finan: detalleSol.Pws_Val_finan,
            Pws_Val_cuo: detalleSol.Pws_Val_cuo,
        };
        const actualizarEstado = await WLOCreditAPIService.addSolicitud(personaRequest);
        if (actualizarEstado.payload.result > 0) {
            toast.success(actualizarEstado.payload.message,{
                duration:5000,
            });
        } else {
            toast.error(actualizarEstado.payload.message,{
                duration:5000,
            });
        }
    };
    const cambioCupo = async (values: any) => {
        const personaRequest = {
            Pws_ident_vende: '',
            Pws_num_solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Identificacion,
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Tip_Perfil: detalleSol.Pws_Tip_Perfil,
            Pws_linea_credito: detalleSol.Pws_linea_credito,
            Pws_Periodic: detalleSol.Pws_Periodic,
            Pws_Tip_person: detalleSol.Pws_Tip_Person,
            Pws_Fideliza: detalleSol.Pws_Fideliza,
            Pws_Tip_Estado: detalleSol.Pws_Tip_Estado,
            Pws_fec_solicitud: detalleSol.Pws_fec_solicitud,
            Pws_Nombres: detalleSol.Pws_Nombres,
            Pws_Apellidos1: detalleSol.Pws_Apellidos1,
            Pws_Apellidos2: detalleSol.Pws_Apellidos2,
            Pws_Telefono1: detalleSol.Pws_Telefono1,
            Pws_Telefono2: detalleSol.Pws_Telefono2,
            Pws_Correo: detalleSol.Pws_Correo,
            Pws_Val_cuoini: detalleSol.Pws_Val_cuoini,
            Pws_Val_pla: values.Pws_Val_pla,
            Pws_Val_finan: detalleSol.Pws_Val_finan,
            Pws_Val_cuo: detalleSol.Pws_Val_cuo,
        };
    }
        // const AsingarCupo = async () => {
        //     var d = new Date(),
        //         dformat = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/');

        //     const creaCupo = {
        //         s_r_ident_cliente: detalleSol.Pws_Identificacion,
        //         s_r_lin_cred: detalleSol.Pws_linea_credito,
        //         s_r_fec_ini_cupo: String(dformat),
        //         s_r_fec_fin_cupo: String(dformat),
        //         s_r_monto_cupo: cupoConsulta,
        //     };
        //     const responde = await WLOCreditAPIService.registraCupo(creaCupo);
        //     console.log(responde);
        //     if (responde.payload.data.R_s_cod === '1') {
        //         cambiarEstado('4');
        //     }
        //     toast.success(responde.payload.result);
        // }


        const handleApproveClick = () => {
            setSelectedOption('Aprobar');
        };
        const handleRejectClick = () => {
            setSelectedOption('Rechazar');
        };
        const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setObservacion(event.target.value);
        };
        const ConfirmarAoR = () => {
            if (selectedOption==='Aprobar'){
                valor.valor.desembolso ? cambiarEstado('4') : toast.success('Porfavor, revise todas las secciones antes de completar esta acción',{
                duration:5000,
                })
            }else if (selectedOption==='Rechazar'){
                valor.valor.desembolso ? cambiarEstado('5') : toast.success('Porfavor, revise todas las secciones antes de completar esta acción',{
                duration:5000,})
            }
        };

        const confirmarIrPinc = () => {
            setModalConfirmPinc(true);
        }
        const irPinc = () => { 
            window.open(process.env.REACT_APP_PINC_FRONT + '/#/PincWeb/sesion/loginAdmin', '_blank');
        }

        const confirmarCreacionPagare = () => {
            //Modal para confirmar la creacion del pagaré
            setShowModal(true);
            
        };

        const generarPagare = async() => {
            /* setPagareGenerado(true);
            setEstado(false); */
            const personaRequest = {
                iTipoPerfilDeudor: "2",
                personaDeudor: {
                    ICodigo: null,
                    CIdentificacion: detalleSol?.Pws_Identificacion,
                    CNombres: detalleSol?.Pws_Nombres,
                    CApellidos: detalleSol?.Pws_Apellidos1,
                    CDireccionResidencia: valor.valor.persona?.Pws_Direccion_res,
                    CNumTelFijo: "0000000",
                    CNumCelular:detalleSol?.Pws_Telefono1,
                    CEmail: detalleSol?.Pws_Correo,
                    CGenero: valor.valor.persona?.Pws_Genero,
                    CEstado: null,
                    ITipoIdent: {
                        ICodigo: 1,
                        CDescripcion: "",
                        CEstado: ""
                    },
                    CCiudad: {
                        ICodigo: valor.valor.persona?.Pws_Ciudad_res.toString(),
                        CNombres: ""
                    }
                },
                iTipoPerfilCodeudor: [],
                personaCodeudor: [],
                requiereCodeudor: false,
                ciudadCodeudor: [],
                ciudadDeudor: valor.valor.persona?.Pws_Ciudad_res.toString(),
                numPagare: "0"
            }
            try {
                const response = await WLOCreditAPIService.generarPagare(personaRequest);
                if (response.bRta) {
                    toast.success(response.sMsj,{
                        duration:5000,
                    });
                    setPagareGenerado(true);
                    setEstado(false);
                } else {
                    toast.error(response.sMsj,{
                        duration:5000,
                    });
                }
            }catch(error){
                console.log(error, "detail");
            }
            
           
        };

        const cambiarEstado = async (estado: any) => {  
            const personaRequest = {
                Pws_ident_vende: '',
                Pws_num_solicitud: detalleSol.Pws_num_solicitud,
                Pws_Identificacion: detalleSol.Pws_Identificacion,
                Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
                Pws_Tip_Perfil: detalleSol.Pws_Tip_Perfil,
                Pws_linea_credito: detalleSol.Pws_linea_credito,
                Pws_Periodic: detalleSol.Pws_Periodic,
                Pws_Tip_Person: detalleSol.Pws_Tip_Person,
                Pws_Fideliza: detalleSol.Pws_Fideliza,
                Pws_Tip_Estado: estado,
                Pws_fec_solicitud: detalleSol.Pws_fec_solicitud,
                Pws_Nombres: detalleSol.Pws_Nombres,
                Pws_Apellidos1: detalleSol.Pws_Apellidos1,
                Pws_Apellidos2: detalleSol.Pws_Apellidos2,
                Pws_Telefono1: detalleSol.Pws_Telefono1,
                Pws_Telefono2: detalleSol.Pws_Telefono2,
                Pws_Correo: detalleSol.Pws_Correo,
                Pws_Val_cuoini: detalleSol.Pws_Val_cuoini,
                Pws_Val_pla: detalleSol.Pws_Val_pla,
                Pws_Val_finan: detalleSol.Pws_Val_finan,
                Pws_Val_cuo: detalleSol.Pws_Val_cuo,
            };
            const cygnusRequestA={
                pws_identificacion: detalleSol.Pws_Identificacion!,
                pws_tip_identificacion: detalleSol.Pws_Tip_Identificacion!,
                pws_num_solicitud: detalleSol.Pws_num_solicitud!,
                pws_estado: "1",
                pws_observacion: observacion,
                pws_gestor: gestor
            }
            const cygnusRequestR={
                pws_identificacion: detalleSol.Pws_Identificacion!,
                pws_tip_identificacion: detalleSol.Pws_Tip_Identificacion!,
                pws_num_solicitud: detalleSol.Pws_num_solicitud!,
                pws_estado: "0",
                pws_observacion: observacion,
                pws_gestor: gestor
            }
            if (estado==="4"){
                const actualizarCygnusAprobado = await WLOCreditAPIService.estadocredito(cygnusRequestA);
            }else{
                const actualizarCygnusRechazado = await WLOCreditAPIService.estadocredito(cygnusRequestR);
            }
            const actualizarEstado = await WLOCreditAPIService.addSolicitud(personaRequest);
            if (actualizarEstado.payload.result > 0) {
                toast.success(actualizarEstado.payload.message,{
                    duration:5000,
                });
                if (estado == '4') {
                    handleSendEmail(true)
                    setEstado(true);
                }else if(estado == '5'){
                    handleSendEmail(false)
                }
                
                valor.valor.datosInformacionServicio(
                    detalleSol.Pws_num_solicitud,
                    detalleSol.Pws_Identificacion,
                    detalleSol.Pws_Tip_Identificacion
                );
            } else {
                toast.error(actualizarEstado.payload.message,{
                    duration:5000,
                });
            }
          ;
        };
        return (
            <div className="box">
                <ul className="title">
                    <li>8.</li>
                    <li>Calificar la Solicitud</li>
                    <RedCheck />
                </ul>
                <div className="submitStartG">
                    <h4>Calificar el estado de la solicitud</h4>
                </div>
                <div className="sub-box">
                    <ul>
                        <li>
                            <label>Calificar: </label>
                        </li>
                        <ul>
                            {valor.valor.infoRetorno.estado != 'Aprobado' || valor.valor.infoRetorno.estado != 'Rechazado'? (
                                <>
                                    <div className={valor.valor.desembolso ? 'submitStartR' : 'submitDisable'}>
                                        <div>
                                            <button onClick={handleApproveClick}>Aprobar</button>
                                        </div>
                                        <div>
                                            <button onClick={handleRejectClick}>Rechazar</button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                <h3>esta solicitud ya fue calificada</h3>
                                </>
                            )}
                        </ul>
                        <li></li>
                        <li></li>
                    </ul>
                    <ul>
                        <li>
                            <label>Observación: </label>
                        </li>
                        <input type='text' className='motivos' placeholder='Cuentanos tu motivo' value={observacion} onChange={handleSelectChange}>

                        </input>
                        <div className={valor.valor.desembolso ? 'submitStartR' : 'submitDisable'}>
                            <button onClick={ConfirmarAoR}>Confirmar</button>
                        </div>
            
                        
                    </ul>
                    
                    {
                        
                        estado &&
                       <>
                        <div className="submitStartG">
                            <h4>¿Desea generar el pagaré?</h4>
                        </div>
                        <div className={valor.valor.desembolso ? 'submitStartR' : 'submitDisable'}>
                            <button onClick={confirmarCreacionPagare}>Generar pagaré</button>
                        </div>
                       </>
                         
                    }
                    {
                        pagareGenerado &&
                        <div className={valor.valor.desembolso ? 'submitStartR' : 'submitDisable'}>
                        <button onClick={confirmarIrPinc}>Ir a pinc</button>
                        </div>
                    }
                    <ModalPagare show={showModal} onHide={() => setShowModal(false)} generarPagare = {generarPagare}/>
                    <ModalPinc show={modalConfirmPinc} onHide={() => setModalConfirmPinc(false)} irPinc = {irPinc}/>
                </div>
            </div>
        );
        }

    interface RenderContainerProps {
        setPopup: React.Dispatch<React.SetStateAction<string>>;
    }
    export const DetailCredit = (props: RenderContainerProps) => {
        const { setPopup } = props;
        const navigate = useNavigate();
        const buscar = useLocation();
        const [persona, DatoPersona] = React.useState({});
        const [datoList, setDatoList] = React.useState(data);
        const [desembolso, setDesembolso] = React.useState(false);
        const [datosReferenciaPersonales, ReferenciaPersonales] = React.useState([]);
        const [datoFinancie, InfoFinanc] = React.useState({});
        const [datosCuentas, CuentasClien] =React.useState({});
        const [datosValorRefer, DatosReferencia] = React.useState({});
        const [tipoSolicitud, setTipoSolicitud] = React.useState({});
        const [listadoCon, setListadoCon] = React.useState([] as any);
        const [dataDesem, datosDesembolso] = React.useState({
            score: 0,
            DataCredito: false,
            listaRestrictiva: false,
            sagrilaft: false,
            estado: 'Simulador',
            color:"1"
        });
        const [infoCredito, setInformacionCredito] = React.useState({});
        const [valorFinanciar, setDataValorFinanciar] = React.useState('');
        const [alldocuments, setDocuments] = React.useState([]);
        const [valorPeps, VAlorPerp] = React.useState([]);
        const [riesgo, setRiesto] = React.useState(false);
        const [arregloValor, valorArreglo] = React.useState([] as any);
        const [propetarios, setDataPropetario] = React.useState([] as any);
        const [requeridos, setDataRequerido] = React.useState([] as any);

        

        const { request, setRequest } = useRequestContext();
        useEffect(() => {
            if (buscar) {
                var valorBusqueda = buscar.search.replace('?', '');
                let arreglo = String(valorBusqueda).split('-');
                getConsultas(arreglo);
                valorArreglo(arreglo);
            }
        }, [buscar, navigate]);

        
        const getConsultas = async (arreglo: string[]) => {
            datosInformacionServicio(arreglo[0], arreglo[1], arreglo[2]);
            let sends: WloCreditoService.SolicitudDetalla = {
                Pws_Num_Solicitud: arreglo[0],
                Pws_Identificacion: arreglo[1],
                Pws_Tip_Identificacion: arreglo[2],
                Pws_Perfil_Acceso: '',
                Pws_Fec_Solicitud: '',
            };
            if (arreglo.length > 0) {
                WLOCreditAPIService.consultaSolicitudCygnusDetal(sends).then(
                    async (valor) => {
                        if (Number(valor.payload.result) > 0) {
                            if (valor.payload.data.datos_persona) {
                                if (valor.payload.data.datos_persona.Datos_persona.length > 0) {
                                    DatoPersona(valor.payload.data.datos_persona.Datos_persona[0]);
                                }
                            }
                            if (valor.payload.data.datos_operfinan) {
                                if (valor.payload.data.datos_operfinan.Datos_operfinan.length > 0) {
                                    InfoFinanc(valor.payload.data.datos_operfinan.Datos_operfinan[0]);
                                }
                            }
                            if (valor.payload.data.datos_peps) {
                                if (valor.payload.data.datos_peps.Datos_peps.length > 0) {
                                    VAlorPerp(valor.payload.data.datos_peps.Datos_peps[0]);
                                }
                            }
                            if (valor.payload.data.datos_cuentaCliente){
                                if(valor.payload.data.datos_cuentaCliente.Datos_cuentaCliente.length>0){
                                    CuentasClien(valor.payload.data.datos_cuentaCliente.Datos_cuentaCliente[0])
                                }
                            }
                            DatosReferenciasPersonales(arreglo[0], arreglo[1], arreglo[2]);
                        }
                    },
                    (error) => {}
                );
            }
        };
        const Busquedatotal = () => {
            getConsultas(arregloValor);
        };
        const consultaEstados=async (arreglo:any)=>{
            const consultaCupo = await WLOCreditAPIService.consultaCupo({
                Pws_Solicitud: arreglo[0],
                Pws_Identificacion: arreglo[1],
                Pws_num_Tip_Ident: arreglo[2],
            })
        }
        const consultarWsCodeudor = async (valor: string, valor2: string, valor3: string) => {
            let codeudors: any = {
                Pws_codigo: '',
                Pws_num_solicitud: valor,
                Pws_Identificacion: valor2,
                Pws_Radic_Codeu: valor,
                Pws_Tip_Identificacion: valor3,
                Pws_Iden_Code: '',
                Pws_Tip_Identificacion_Code: '',
                Pws_Tip_Codeu: '',
            };
            const allCodeudor = await WLOCreditAPIService.addCodeudor(codeudors);
            if (Number(allCodeudor.payload.result) > 0) {
                if (allCodeudor.payload.data.datos_codeu.Datos_codeudor.length > 0) {
                    agregarValorDeudor(allCodeudor.payload.data.datos_codeu.Datos_codeudor, 0, valor, [], []);
                }
            }

            extraerdocumentos(valor, valor2, valor3);
        };
        const agregarValorDeudor = async (datosCodeudor: any, id: any, numsol: any, arreglo: any, arreglo1: any) => {
            let retprp: any[] = [];
            retprp = retprp.concat(arreglo);
            let retprp2: any[] = [];
            retprp2 = retprp2.concat(arreglo1);

            if (datosCodeudor.length <= id) {
                setDataRequerido(retprp);
                setDataPropetario(retprp2);
            } else {
                let sends: WloCreditoService.SolicitudDetalla = {
                    Pws_Num_Solicitud: numsol,
                    Pws_Identificacion: datosCodeudor[id].Pws_Iden_Code,
                    Pws_Tip_Identificacion: datosCodeudor[id].Pws_Tip_Identificacion_Code,
                    Pws_Perfil_Acceso: '',
                    Pws_Fec_Solicitud: '',
                };

                let retorno = await WLOCreditAPIService.consultaSolicitudCygnus(sends);
                if (Number(retorno.payload.result) > 0) {
                    if (datosCodeudor[id].Pws_Tip_Codeu === '2') {
                        let dato = {
                            codeudor: datosCodeudor[id],
                            data: retorno.payload.data.datos_persona.Datos_persona[0],
                        };
                        retprp.push(dato);
                    } else {
                        let dato = {
                            codeudor: datosCodeudor[id],
                            data: retorno.payload.data.datos_persona.Datos_persona[0],
                        };
                        retprp2.push(dato);
                    }

                    agregarValorDeudor(datosCodeudor, id + 1, numsol, retprp, retprp2);
                }
            }
        };
        const datosInformacionServicio = async (valor: string, valor2: string, valor3: string) => {
            const result = await WLOCreditAPIService.addSolicitud({
                Pws_num_solicitud: valor,
                Pws_Identificacion: valor2,
                Pws_Tip_Identificacion: valor3, //Valor que pide el ws pero a nivel de UI no existe
            });
            if (result.payload.result >= 0) {
                let tipoEstado = 'Simulador';
                let colorestado="1"
                if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 1) {tipoEstado="Simulador"; colorestado="1"};
                if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 2) {tipoEstado="Confirmación información"; colorestado="1"}
                if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 3) {tipoEstado="Firma documentos"; colorestado="1" }
                if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 4) {tipoEstado="Aprobado"; colorestado="2" } 
                if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 5) {tipoEstado="Rechazado"; colorestado="3" }
                if(Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado)==12){tipoEstado="En estudio"; colorestado="1"}
                
                dataDesem.estado = tipoEstado;
                dataDesem.color=colorestado;
                datosDesembolso(dataDesem);
                setTipoSolicitud(dataDesem.estado);
                if (dataDesem.estado=="Aprobado"|| dataDesem.estado=="Rechazado"){
                    const valores: {
                            bg1: string;
                            text: string;
                            bg2: string;
                            abcilon: string;
                            revisado: boolean;
                        }[] = datoList.map((dato, i) => {
                            return {
                                ...dato,
                                abcilon: '',
                                bg2: 'none',
                                bg1: '#1B9543',
                                revisado: false,
                            };                           
                        });
                        setDatoList(valores);
                }          
                setRequest({
                    ...request,
                    valorSolicitud: result.payload.data.Datos_Sol.Datos_Sol[0],
                });

                setInformacionCredito(result.payload.data.Datos_Sol.Datos_Sol[0]);
                setDataValorFinanciar(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Val_finan);
            }

            consultarWsCodeudor(valor, valor2, valor3);           
        };
        const extraerdocumentos = async (valor: string, valor2: string, valor3: string) => {
            const result = await WLOCreditAPIService.ConsultarDocumentos({
                Pws_num_solicitud: valor,
                Pws_Identificacion: valor2,
                Pws_Tip_Identificacion: valor3, //Valor que pide el ws pero a nivel de UI no existe
            });
            if (result.payload.data.Pws_R_s_cod == '1') {
                if (result.payload.data.datos_doc.datos_Doc) {
                    setDocuments(result.payload.data.datos_doc.datos_Doc);
                } else {
                    setDocuments([]);
                }
            }
            extraerdatcredRiesgo(valor, valor2, valor3);
        };

        const extraerdatcredRiesgo = async (valor: string, valor2: string, valor3: string) => {
            const result = await WLOCreditAPIService.regDataCreditoAndSagrilaft({
                Pws_num_solicitud: valor,
                Pws_Identificacion: valor2,
                Pws_Tip_Identificacion: valor3, //Valor que pide elcomo  ws pero a nivel de UI no existe
            });
            var i_codigo = '';
            const entidadter = await AdminService.entidadTer(i_codigo);
            if (result.payload) {
                if (result.payload.data) {
                    if (result.payload.data.datos_datacre) {
                        if (result.payload.data.datos_datacre.datos_datacredito) {
                            var listado = new Array();
                            var listDataCredito = result.payload.data.datos_datacre.datos_datacredito;
                            for (let index = 0; index < listDataCredito.length; index++) {
                                let listadoConsultas = {
                                    estado_restop: String,
                                    tipo_valida: String,
                                    c_descripcion: String,
                                    puntaje: String,
                                };

                                listadoConsultas.estado_restop = listDataCredito[index].Pws_estado_resotp;
                                listadoConsultas.tipo_valida = listDataCredito[index].Pws_Tip_val;
                                listadoConsultas.c_descripcion =
                                    entidadter.payload.data.ListRef.Datos_Referencias[3].s_descripcion;
                                listadoConsultas.puntaje = listDataCredito[index].Pws_Puntaje;
                                listado[0] = listadoConsultas;
                                // if (Number(listDataCredito[index].Pws_Entidad_Consul) == 2) {
                                //     dataDesem.sagrilaft = Number(listDataCredito[index].Pws_estado_resotp) == 1 ? true : false;
                                //     datosDesembolso(dataDesem);
                                //     if (Number(listDataCredito[index].Pws_estado_resotp) == 1) {
                                //         setRiesto(true);
                                //     }
                                // }
                                if (Number(listDataCredito[index].Pws_Entidad_Consul) == 4) {
                                    dataDesem.DataCredito = Number(listDataCredito[index].Pws_estado_resotp) == 1 ? true : false;
                                    datosDesembolso(dataDesem);
                                    if (Number(listDataCredito[index].Pws_estado_resotp) == 1) {
                                        setRiesto(true);
                                    }
                                }

                                if (Number(listDataCredito[index].Pws_Entidad_Consul) == 5) {
                                    if (Number(listDataCredito[index].Pws_estado_resotp) == 1) {
                                        setRiesto(true);
                                    }
                                    dataDesem.score = Number(listDataCredito[index].Pws_Puntaje);
                                }
                            }
                            setListadoCon(listado);
                        }
                    }
                }
            }
        };
        const DatosReferenciasPersonales = (valor: string, valor2: string, valor3: string) => {
            WLOCreditAPIService.addReferences({
                s_num_solicitud: valor!,
                s_identificacion: valor2!,
                s_tipo_identificacion: valor3!,
                s_ref_tipo: '',
                s_ref_parentes: '',
                s_ref_nomcomple: '',
                s_ref_telfij: '',
                s_ref_telcel: '',
            }).then((ValorReferencias) => {
                if (Number(ValorReferencias.payload.result) > 0) {
                    ReferenciaPersonales(ValorReferencias.payload.data.datos_referencia.datos_refer);
                }
                DatosReferencia({
                    s_num_solicitud: valor!,
                    s_identificacion: valor2!,
                    s_tipo_identificacion: valor3!,
                    s_ref_tipo: '',
                    s_ref_parentes: '',
                    s_ref_nomcomple: '',
                    s_ref_telfij: '',
                    s_ref_telcel: '',
                });
            });
        };

        const changeDatoList = (...ids: number[]) => {
            const valor: {
                bg1: string;
                text: string;
                bg2: string;
                abcilon: string;
                revisado: boolean;
            }[] = datoList.map((dato, i) => {
                if (!ids.includes(i)) {
                    return dato;
                }

                return {
                    ...dato,
                    abcilon: '',
                    bg2: 'none',
                    bg1: '#1B9543',
                    revisado: true,
                };
            });

            setDatoList(valor);
            for (let i = 0; i < 6; i++) {
                // 9 es la ultima sección, antes de la calificacion de solicitud
                if (valor[i].revisado === false) {
                    setDesembolso(false);
                    break;
                } else {
                    setDesembolso(true);
                }
            }
        };
        let valor="";
        return (
            <div className="detailCredit detail">
                <div className="header">
                    <Fab
                        variant="extended"
                        color="neutral"
                        aria-label="add"
                        className="hooverButtonRed"
                        onClick={() => {
                            navigate(AppRoutes.SOLICITUDES_DE_CREDITO, { replace: true });
                        }}>
                        <ArrowBack className="hooverButtonRedsvg" sx={{ mr: 1 }} />
                        {'Atrás'}
                    </Fab>
                    <h2 className="title">Detalle del crédito</h2>

                    {/* {riesgo ? (
                        <>
                            <p className="presentar"> {dataDesem.estado}</p>

                        </>
                    ) : (
                        <>
                            <p className="presenta"> {dataDesem.estado}</p>
                        </>
                    )} */}
                 
                 <p className={dataDesem.color == "1" ? "estadoMedio": (dataDesem.color=="2" ? "estadoAceptado":"estadoNegado")}> {dataDesem.estado} </p>

                   

                            
                </div>
                <div className="body">
                    <div className="sidebar">
                        <h4>Solicitud</h4>
                        {datoList.map((info, i) => {
                            return <CreditSideBar key={i} info={info} i={i} datodesembolso={desembolso} />;
                        })}
                    </div>
                    <div className="mainDetail">
                        <Box1 dato={infoCredito} />
                        <Box2 valorPersonal={{ person: persona, Busquedatotal, setPopup, changeDatoList }} tipoSolicitud={tipoSolicitud} datosCuentas={datosCuentas} datosInformacionServicio={datosInformacionServicio}  />
                        <Box3
                            valorFinancie={{
                                valorinic: datoFinancie,
                                datopeps: valorPeps,
                                Busquedatotal,
                                datosIngr: arregloValor,
                                person: persona,
                                changeDatoList,
                            }}
                            tipoSolicitud={tipoSolicitud} datosInformacionServicio={datosInformacionServicio}
                        />
                        <Box4 valorDocuments={{ alldocuments: alldocuments, changeDatoList, valor: arregloValor, Busquedatotal}} tipoSolicitud={tipoSolicitud} datosInformacionServicio={datosInformacionServicio}/>
                        <Box5 valorReferencias={{datosrefe: datosReferenciaPersonales,Busquedatotal,values: datosValorRefer,changeDatoList}} tipoSolicitud={tipoSolicitud} datosInformacionServicio={datosInformacionServicio} />
                        {/* {/* <Box6 propetarios={{valores:propetarios,arreglos:arregloValor,changeDatoList}}/> */}
                        <Box0 terceros={{valores:listadoCon,arreglos:arregloValor,changeDatoList,infoCredito,}} tipoSolicitud={tipoSolicitud} datosInformacionServicio={datosInformacionServicio}/>
                        <Box7 requeridos={{valores:requeridos,arreglos:arregloValor,changeDatoList,infodeSolicitud:infoCredito}}/>
                        <Box8 dato={{ setDesembolso, infoRetorno: dataDesem, changeDatoList, valorAFinanciar: valorFinanciar}} tipoSolicitud={tipoSolicitud} datosInformacionServicio={datosInformacionServicio} />
                        {desembolso?<> <Box9 valor={{valor:arregloValor ,setDesembolso,infoRetorno:dataDesem,changeDatoList,valorAFinanciar:valorFinanciar, infoCredito, desembolso, datosInformacionServicio,consultaEstados, persona: persona}}/></>:<></>} 
                    </div>
                </div>
            </div>
        );
    };
    export const Box0 = (terceros: any) => {
        const [revisado, setRevisado] = React.useState(true);
        const [tercero, agregapro] = React.useState([] as any);
        const [revisadoDisable, setRevisadoDisable] = React.useState(true);
        const { tipoSolicitud } = terceros;
        useEffect(() => {
            agregapro(terceros.terceros.valores);
            if (terceros.terceros.valores.length == 0) {
                setRevisadoDisable(false);
            } else {
                setRevisadoDisable(true);
            }
            if (tipoSolicitud === 'Aprobado' || tipoSolicitud === 'Rechazado') {
            setRevisado(false);
            }
        }, [terceros]);
        return (
            <div className="box">
                <ul className="title">
                    <li>6.</li>
                    <li>Consulta a terceros</li>
                    <Person />
                </ul>
                <div className="table">
                    <ConsultaTercerosTable terceros={tercero} />
                </div>
                {revisadoDisable ? (
                    <>
                        <div className="submit" id="informacionverificacion">
                            {revisado ? (
                                <>
                                    <button
                                        type="button"
                                        style={{ marginTop: '10px' }}
                                        onClick={() => {
                                            terceros.terceros.changeDatoList(5);
                                            setRevisado(false);
                                        }}>
                                        Revisado
                                    </button>
                                </>
                            ) : (
                                <>
                                    <GreenCheck />
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="submitDis">
                            <button type="button" style={{ marginTop: '10px' }} disabled onClick={() => {}}>
                                Revisado
                            </button>
                        </div>
                    </>
                )}
            </div>
        );
    };

    export const ConsultaTercerosTable = (terceros: any) => {
        const [tercero, agregapro] = React.useState([] as any);
        useEffect(() => {
            agregapro(terceros.terceros);
        }, [terceros]);
        return (
            <div className="referenceTable">
                <div className="table">
                    <div className="responsiveFix">
                        <div className="responsiveAuto">
                            <ul>
                                <li className="listItem listHeader">Entidad</li>
                                <li className="listItem listHeader">Puntaje</li>
                                <li className="listItem listHeader">Riesgo</li>
                                <li className="listItem listHeader">Consultar</li>
                            </ul>
                            {tercero?.map((info: any, i: any) => {
                                return <SolicItem key={i} info={info} />;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    export const Box7 = (requeridos:any) => {
        const [requerido,agregapro]=React.useState([] as any) 
        const [revisado,setRevisado] =React.useState(true)
        useEffect(()=>{
        agregapro(requeridos.requeridos.valores)
        },[requeridos])
        return(
            <div className="box">
                <ul className="title">
                    <li>7.</li>
                    <li>Información codeudor requerido </li>
                    <Person/>
                </ul>
                <div className="table">
                    <CodeudorTable propetarios={{valor:requerido,type:'req',arreglo:requeridos.requeridos.arreglos}}/>
                </div>
                <div className="submit">
                {revisado?<>
                        <button type="button" style={{marginTop:"10px"}} onClick={()=>{
                            requeridos.requeridos.changeDatoList(7)
                            setRevisado(false)
                            }}>Revisado</button>
                    </>:<> <GreenCheck/></>}
                </div> 
            </div>
        );
    }
    const SolicItem = (info: any) => {
        const irpara = (descripcion: string) => {
            if (descripcion == 'Score Cygnus') {
                toast.success('Score Cygnus',{
                    duration:5000,
                });
            }
            if (descripcion == 'DataCredito') {
                toast.success('DataCredito',{
                    duration:5000,
                });
            }
            // if (descripcion == 'Sagrilaft') {
            //     toast.success('Sagrilaft',{
            //         duration:5000,
            //     });
            // }
        };
        return (
            <>
                <ul>
                    <li className="listItem firstItem">{info.info.c_descripcion}</li>
                    <li className="listItem">{info.info.puntaje} </li>
                    <li className="listItem">{info.info.estado_restop == '1' ? 'SI' : 'NO'}</li>
                    <li className="listItem">
                        {info.info.estado_restop == '3' ? (
                            <div
                                onClick={() => {
                                    irpara(info.info.c_descripcion);
                                }}>
                                <Search />
                            </div>
                        ) : (
                            <div>
                                <GreenCheck />
                            </div>
                        )}
                    </li>
                </ul>
            </>
        );
    };

    export const Box10 = (valorDocuments: any) => {
        const [update, setUpdate] = React.useState(false);
        const [UpdateDocumentosLegales, setUpdateDocumentosLegales] = React.useState(false);
        const [alldocuments, setDocuments] = React.useState([
            {
                Pws_Doc_Tipo: 1,
                Pws_Doc_nombre: '',
                Pws_Clave_doc: '',
                Pws_Doc_estado: '',
                Pws_Doc_ruta: '',
            },
            {
                Pws_Doc_Tipo: 2,
                Pws_Doc_nombre: '',
                Pws_Clave_doc: '',
                Pws_Doc_estado: '',
                Pws_Doc_ruta: '',
            },
            {
                Pws_Doc_Tipo: 3,
                Pws_Doc_nombre: '',
                Pws_Clave_doc: '',
                Pws_Doc_estado: '',
                Pws_Doc_ruta: '',
            },
            {
                Pws_Doc_Tipo: 4,
                Pws_Doc_nombre: '',
                Pws_Clave_doc: '',
                Pws_Doc_estado: '',
                Pws_Doc_ruta: '',
            },
            {
                Pws_Doc_Tipo: 5,
                Pws_Doc_nombre: '',
                Pws_Clave_doc: '',
                Pws_Doc_estado: '',
                Pws_Doc_ruta: '',
            },
        ]);
        const { user, setUser } = useLoginContext();
        useEffect(() => {
            if (valorDocuments.valorDocuments.alldocuments) {
                if (valorDocuments.valorDocuments.alldocuments.length > 0) {
                    let valordocumentos = [
                        {
                            Pws_Doc_Tipo: 1,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 2,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 3,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 4,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 5,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                    ] as any;
                    valorDocuments.valorDocuments.alldocuments.forEach((element: any) => {
                        if (element.Pws_Doc_estado == '1') valordocumentos[Number(element.Pws_Doc_Tipo) - 1] = element;
                    });
                    setDocuments(valordocumentos);
                }
            }
        }, [valorDocuments]);
        const [revisado, setRevisado] = React.useState(true);
        const cambiarUpdat = () => {
            if (update) {
                valorDocuments.valorDocuments.Busquedatotal();
            }
            setUpdate(!update);
        };
        const cambiarUpdateDocuLega = () => {
            if (UpdateDocumentosLegales) {
                valorDocuments.valorDocuments.Busquedatotal();
            }
            setUpdateDocumentosLegales(!UpdateDocumentosLegales);
        };

        const handleFileInputChange = (e: any) => {
            const target = e.target;
            if (target.files && target.files[0]) {
                const maxAllowedSize = 5 * 1024 * 1024;
                if (target.files[0].size > maxAllowedSize) {
                    toast.error('Seleccione un archivo de tamaño máximo 5mb.',{
                        duration:5000,
                    });
                    target.value = '';
                }
            }
            let file: any = '';
            file = e.target.files[0];
            getBase64(file)
                .then(async (result) => {
                    const document = result as string;
                    const documentRequest = {
                        Pws_num_solicitud: valorDocuments.valorDocuments.valor[0],
                        Pws_Identificacion: valorDocuments.valorDocuments.valor[1],
                        Pws_Tip_Identificacion: valorDocuments.valorDocuments.valor[2],
                        Pws_Doc_Tipo: target.dataset.id,
                        Pws_Doc_nombre: file.name,
                        Pws_Doc_estado: '1',
                        Pws_Doc_ruta: '1',
                        Pws_Clave_doc: document,
                    };

                    (async () => {
                        const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                        if (Number(solicitud.payload.result) > 0) {
                            toast.success('Subido el Nuevo Archivo',{
                                duration:5000,
                            });
                        }
                    })();
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const getBase64 = (file: any) => {
            return new Promise((resolve) => {
                let baseURL: string | ArrayBuffer | null = '';
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    baseURL = reader.result;
                    resolve(baseURL);
                };
            });
        };
        return (
            <div className="box">
                <ul className="title">
                    <li>1.</li>
                    <li>Documentos de Garantia </li>
                    <File />
                    <div onClick={cambiarUpdateDocuLega}>
                        <Edit />
                    </div>
                </ul>
                {/* documentos legales */}

                <div className="documents">
                    {alldocuments.map((valorDocumentss: any, index) => {
                        let tipo = '';
                        let documentss = '';
                        let pasar = true;
                        if (Number(valorDocumentss.Pws_Doc_Tipo) == 1) {
                            tipo = 'Pagare/carta de instrucciones';
                            documentss = valorDocumentss.Pws_Doc_nombre;
                        } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 2) {
                            tipo = 'Contrato aval';
                            documentss = valorDocumentss.Pws_Doc_nombre;
                        } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 3) {
                            tipo = 'Garantia mobiliaria';
                            documentss = valorDocumentss.Pws_Doc_nombre;
                        } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 4) {
                            tipo = 'Compraventa';
                            documentss = valorDocumentss.Pws_Doc_nombre;
                        } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 5) {
                            tipo = 'Dación de pago';
                            documentss = valorDocumentss.Pws_Doc_nombre;
                        } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 6) {
                            tipo = 'Condiciones financieras';
                            documentss = valorDocumentss.Pws_Doc_nombre;
                        }
                        const download = () => {
                            const link = document.createElement('a');
                            link.href = valorDocumentss.Pws_Clave_doc;
                            link.setAttribute('download', documentss);
                            link.setAttribute('target', '_blank');
                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();
                            link.remove();
                        };
                        if (pasar) {
                            return (
                                <>
                                    <div
                                        key={index}
                                        className={update ? 'redBox' : documentss ? 'redBox check' : 'redBox'}
                                        onClick={(eve: any) => {
                                            if (UpdateDocumentosLegales) {
                                                eve.currentTarget.querySelector('input')?.click();
                                            } else {
                                                if (documentss) {
                                                    download();
                                                }
                                            }
                                        }}>
                                        {UpdateDocumentosLegales ? (
                                            <>
                                                <input
                                                    data-id={valorDocumentss.Pws_Doc_Tipo}
                                                    type="file"
                                                    name="file-input"
                                                    id="file-input"
                                                    className="file-input__input"
                                                    accept="application/pdf, image/png, image/jpg, image/jpeg"
                                                    onChange={handleFileInputChange}
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {UpdateDocumentosLegales ? (
                                            <>
                                                <Documentos />
                                            </>
                                        ) : !documentss ? (
                                            <>
                                                <Documentos />
                                            </>
                                        ) : (
                                            <>
                                                <Documentosred />
                                            </>
                                        )}
                                        <p>
                                            {tipo}
                                            {UpdateDocumentosLegales ? ' (Actualizar Documentos de Garantia)' : ''}
                                        </p>
                                    </div>
                                </>
                            );
                        } else {
                            return <></>;
                        }
                    })}
                </div>
                <div className="submit">
                    {revisado ? (
                        <>
                            <button
                                type="button"
                                style={{ marginTop: '10px' }}
                                onClick={() => {
                                    valorDocuments.valorDocuments.changeDatoList(4);
                                    setRevisado(false);
                                }}>
                                Revisado
                            </button>
                        </>
                    ) : (
                        <>
                            {' '}
                            <GreenCheck />
                        </>
                    )}
                </div>
            </div>
        );
    };

