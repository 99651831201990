import axios from "axios";
import {WloCreditoService} from "../types";

import React from "react";

export class WLOCreditAPIService {
    static avanceRot(avanceRot: { ps_ident_cli: any; ps_linea_cred: any; pn_monto: any; pn_plazo: any; pn_for_pla: string; pn_for_pag: string; pn_periodic: string; pf_fecha_sol: string; ps_estado: string; }): any {
        throw new Error('Method not implemented.');
    }

    static async addConyugue(conyugue: Partial<WloCreditoService.ConyugueRequest>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/conyuge', conyugue);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getDocumento(datodocumento: Partial<WloCreditoService.documentoSol>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/documentos-sol/documentofirma', datodocumento );
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getEmail(InfoSend:{
        id:string,
        number:string,
        email:string,
        typeIdent:string,
        numbIdent:string,
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/emails/estudio', InfoSend);
            return data;
        } catch (e) {
            throw e;
        }
    }
    
    static async consultaCupo(cupo:{
        Pws_Solicitud: string,
        Pws_Identificacion: string,
        Pws_num_Tip_Ident: string,
    }): Promise<any> {
    try {
        const {data} = await axios.post(process.env.REACT_APP_API+'/requests/consultacupo', cupo);
        return data;
    } catch (e) {
        throw e;
    }
}

    
    
    static async addCupo(cupo:{
        Pws_Solicitud: string,
        Pws_Identificacion: string,
        Pws_num_Tip_Ident: string,
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/creaCupo', cupo);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async registraCupo(body: {
        s_r_ident_cliente: String,
        s_r_lin_cred: string,
        s_r_fec_ini_cupo: String,
        s_r_fec_fin_cupo: string,
        s_r_monto_cupo: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/registrocupo', body);
            return data;
        } catch (e) {
            return returnResponseError(e);
        }
    }

    
    static async estadocredito(estadocredito:{  
        pws_identificacion: string,
        pws_tip_identificacion:  string,
        pws_num_solicitud:  string,
        pws_estado:  string,
        pws_observacion: string,
        pws_gestor: string,
}): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/estadocredito', estadocredito);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async EvaluaScore(evaluaScore:{  
        ps_radicado: string,
    ps_identificacion:  string,
    ps_cod_lin:  string,
    ps_tipoCliente:  string,
    ps_tip_deucode:  string,
}): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/evaluaScore', evaluaScore);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async addAddress(address:{
        Pws_Tip_Consulta: string,
        Pws_Identificacion: string,
        Pws_Tip_Identificacion: string,
        Pws_Tipo: string,
        Pws_Num_Principal: string,
        Pws_Num_Secundario: string,
        Pws_SufijoPri:string,
        Pws_SufijoSec: string,
        Pws_NumComplementario: string,
        Pws_Complemento1: string,
        Pws_NumComplementario1: string,
        Pws_Complemento2: string,
        Pws_NumComplementario2: string,
        Pws_Barrio: string,
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/operations/adrress', address);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addCodeudor(codeudor: Partial<WloCreditoService.CodeudorRequest>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/codeudor', codeudor);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async grabar_cignus(envio:{
        Pws_Identificacion : string,
	Pws_Tip_Identificacion:string,
    Pws_Tip_Perfil: string,
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/graba-sol-cignus', envio);
            return data;
        } catch (e) {
            throw e;
        }
    }
    
    static async addSolicitudPersona(solicitudPersona: Partial<WloCreditoService.SolicitudPersonaRequest>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/person', solicitudPersona);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addSolicitud(solicitud: Partial<WloCreditoService.SolicitudRequest>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/', solicitud);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async consultaSolicitudCygnusDetal(solicitud: Partial<WloCreditoService.SolicitudDetalla>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/consultadetalle', solicitud);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async consultaSolicitudCygnus(solicitud: Partial<WloCreditoService.SolicitudRequest>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/consulta', solicitud);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async consultaSolicitudListadoCygnus(solicitud: Partial<WloCreditoService.SolicitudListadoRequest>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/consultalistado', solicitud);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async consultaSolicitudANI(solicitud: Partial<WloCreditoService.SolicitudRequest>): Promise<any> {
        try {
            const randomForSearch = Math.floor(Math.random() * 100000).toString() + solicitud.Pws_Identificacion!;
            return await WLOCreditAPIService.consultaANI(randomForSearch, solicitud.Pws_Identificacion!, solicitud.Pws_Tip_Identificacion!);
        } catch (e) {
            throw e;
        }
    }

    static async getAccessTokenANI(): Promise<any> {
        try {
            const body = {
                "idAplicacion": "85d2ca22-0f99-4d23-965d-70ad1afdce30",
                "usuario": "usu_EBS",
                "contrasena": "riPNstFiJo",
            }
            const {data} = await axios.post(`https://apippagare.olimpiait.com:8091/Login`, body);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async getListasRestrictivas(listasRest:Partial<WloCreditoService.listaRequest> ): Promise<any>{
        try {
            const response = await axios.post(process.env.REACT_APP_API+'/listas/listasRestrictivas',listasRest);
            return response.data;
        } catch (e) {
            throw e;
        }
    }
    static async consultaANI(idProcesoCliente: string, documento: string, tipoDocumento: string): Promise<any> {
        try {
            const formatedTipoDocumento = WLOCreditAPIService.getTipoDocumentoCygnusToANI(tipoDocumento);
            const accessToken = await WLOCreditAPIService.getAccessTokenANI();
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": accessToken,
                },
            };
            const body = {
                "idProcesoCliente": idProcesoCliente,
                "documento": documento,
                "tipoDocumento": formatedTipoDocumento
            };
            const {data} = await axios.post(`https://apippagare.olimpiait.com:8091/ANI/ConsultaANI`, body, config);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addPeps(peps: Partial<WloCreditoService.PepsRequest>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/operations/peps', peps);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addReferences(referencias: Partial<WloCreditoService.ReferenciasRequest>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/operations/references', referencias);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addFinancialInformation(financialInformation: Partial<WloCreditoService.OperacionesFinancierasRequest>): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/operations/financial', financialInformation);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async sendEmailModelo(): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/emails');
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async sendEmailCotizacion(emailInfo: {
        dateSolicitud: string,
        product: string,
        price: string,
        cuotas: string,
        valorCuota: string,
        email: string,
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/emails/cotizacion', emailInfo);
            return data;
        } catch (e) {
            throw e;
        }
    }
    
        static async sendEmailFirmaInicial(emailInfo: {
            email: string,
            dateSolicitud: string,
            numsol:string,
        }): Promise<any> {
            try {
                const {data} = await axios.post(process.env.REACT_APP_API+'/emails/firma', emailInfo);
                return data;
            } catch (e) {
                throw e;
            }
        }
    
        static async confirmacionEmail(emailInfo: {
            solicitud: string,
            email: string,
            dateSolicitud: string,
            respuesta: boolean,
        }): Promise<any> {
            try {
                const {data} = await axios.post(process.env.REACT_APP_API+'/emails/confirmacion', emailInfo);
                return data;
            } catch (e) {
                throw e;
            }
        }
    

    static async sendOTP(body: any): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/reg-otp', body);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async verifyCodeRegOtp(body: any): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/reg-otp/verify-code', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }

    static async consultaConsolidada(body: {
        datoConsultar: number,
        tipoDocumento: string,
        numeroSolicitud: number,
        fechaSolicitud: string,
        email: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/consulta-consolidada', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }
    static async consultDataCredito(body: {
        identificacion: string,
        primer_apellido: string,
        tipoIdentificacion: string,
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/consultadatacredito', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }
    static async regDataCreditoAndSagrilaft(body: {
        Pws_num_solicitud? : string,
    Pws_Identificacion? : string,
    Pws_Tip_Identificacion?: string,
    Pws_Fec_gen?: string,
    Pws_estado_resotp?: string,
    Pws_Tip_val?: string,
    Pws_Puntaje?: string,
    Pws_Entidad_Consul?: string,
    Pws_Num_cuodat?: string
}): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/requests/regDataCredandSagrilaft', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }
    static async addDocuments(body: any): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/documentos-sol',body);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async ConsultarDocumentos(body: any): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/documentos-sol/consultdocumentos',body);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async addReference(): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/operations/references');
            return data;
        } catch (e) {
            throw e;
        }
    }


    static getTipoDocumentoCygnusToANI(tipoDocumento: string) {
        switch (tipoDocumento) {
            case '13':
                return 'CC'
            case '22':
                return 'CE'
            case '31':
                return 'NIT'
            default:
                return 'CC'
        }
    }

    static async getInventario(inventarioRequest: WloCreditoService.InventarioRequest) {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/inventory', inventarioRequest);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async getSimulador(simuladorRequest: Partial<WloCreditoService.SimuladorCreditoRequest>) {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/simulador-credito/liq', simuladorRequest);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async createCredito(creaCreditoRequest: Partial<WloCreditoService.creCreditRequest>) {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/simulador-credito/crear-credito', creaCreditoRequest);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async addBank(bankRequest: Partial<WloCreditoService.BankRequest>){
        try{
            const {data} = await axios.post(process.env.REACT_APP_API+'/admin/cuentas',bankRequest)
            return data;
        }catch (e) {
            throw e;
        }
    }
    
    static async generarPagare(solicitudPersona:any) {
        try {
            const {data} = await axios.post(process.env.REACT_APP_PINC_API+'/CrearSolicitudPagare', solicitudPersona);
            return data;
        } catch (e) {
            throw e;
        }
    }
}

const returnResponseError = (error: any) => {
    const {response} = error;
    const {data} = response;
    const {errors, message} = data;
    throw {errors, message};
}

const returnResponseSuccess = (response: any) => {
    const {payload, message} = response;
    return {payload, message}
}