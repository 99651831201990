import {useIntl} from "react-intl";
import {Field} from "formik";
import React, {useState,useEffect} from "react";
import _ from "lodash";
import toast from "react-hot-toast";
import {AdminService, WLOCreditAPIService} from "../../../services";
import {terms, typeCredit} from "../../../__mocks__/data";
import Moment from 'moment';
import {useRequestContext} from "../../../hooks/useRequestContext";
import ClockLoader from "react-spinners/ClockLoader";
import ParseNumberThousandMedium from "../../shared/Utilities/ParseNumberThousandMedium";
import ParseNumberSmallertext from "../../shared/Utilities/ParseNumberSmallerText";
import NumberFormat from 'react-number-format';

interface FormCreditSimulatorProp {
    errors?: any,
    values?: any,
}

export function FormCreditSimulatorMobile(props: FormCreditSimulatorProp) {
    const {request, setRequest} = useRequestContext();
    const {errors, values} = props;
    const intl = useIntl();
    const [valueQuote, setValueQuote] = React.useState(0);
    const [valueToFinance, setValueToFinance] = React.useState(0);
    const [isLoadingSimulator, setIsSimulator] = useState(false);
    const [isLoadingEmail, setIsEmail] = useState(false);
    const [listCredit, setListcredit] = useState([]);
    const [listPeriodos, setPeriodos] = useState([]);
    const [one] = useState(false);
    useEffect(()=>{
        getValueListCreditos()
    },[one])
    const getValueListCreditos =async () =>{
        const valor= await AdminService.getlineascredito();
        if(valor.payload.data.ListLineaCredito.Datos_LineasCredito.length>0){
            setListcredit(valor.payload.data.ListLineaCredito.Datos_LineasCredito)
        }
        const valor2= await AdminService.getPeriodos();
        if(valor2.payload.data.ListPerido.Datos_Periodo.length>0){
            setPeriodos(valor2.payload.data.ListPerido.Datos_Periodo)
        }
    }
    const handleSendEmail = async (valorcuota:string) => {
        setIsEmail(true);
        if (_.isEmpty(values.pws_cod_credi!) || _.isEmpty(values.Pws_Val_pla!) || _.isEmpty(values.pws_nro_cuotas!) || _.isEmpty(values.Pws_Correo!)
         ) {
            toast.error('Debe indicar los valores [Correo, Opción de Crédito, Plazo, Valor cuota inicial, Nro. Cuotas] para poder calcular',{
                duration:5000,
            });
        } else {
            try {
                const emailInfo = {
                    dateSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
                    product: request.nameProduct!,
                    price: request.valueToFinance!,
                    cuotas: values.pws_nro_cuotas, 
                    valorCuota: valorcuota,
                    email: values.Pws_Correo!,
                }
                console.log(emailInfo)
                const resultEnvioCorreo = await WLOCreditAPIService.sendEmailCotizacion(emailInfo);
                if (resultEnvioCorreo) {
                    toast.success('Se ha enviado con exito el email con la cotización',{
                        duration:5000,
                    });
                    setIsEmail(false);
                }
            } catch (error) {
                setIsSimulator(true);
                toast.error('En este momento no podemos atender su solicitud',{
                    duration:5000,
                });
                setTimeout(() => {
                    setIsSimulator(false);
                }, 5000);
            }
        }
    }

    const handleCalculatedQuote = async (e: any) => {
        e.preventDefault();
        setIsSimulator(true);
        const montoVehiculo = request.priceProduct;
        let cuotaFormated;
        let cuotaNumberFormated;
        let plazo: string;
        let monto;
        if (values) {
            if (_.isEmpty(values.pws_cod_credi!) || _.isEmpty(values.Pws_Val_pla!)  || _.isEmpty(values.Pws_Identificacion) || _.isEmpty(values.pws_nro_cuotas!)) {
                toast.error('Debe indicar los valores [Opción de Crédito, Plazo, Valor cuota inicial, Nro de Cuotas e Identificación] para poder calcular',{
                    duration:5000,
                });
                setIsSimulator(false);
            } else {
                try {
                    // cuotaFormated = values.Pws_Val_cuoini.replaceAll(',', '');
                    cuotaNumberFormated = values.pws_nro_cuotas.replaceAll(',', '');
                    monto = values.Pws_monto.replaceAll(',', '');
                    
                    if (parseFloat(cuotaNumberFormated) > 999) {
                        toast.error('El nro de cuota no puede ser mayor a 999',{
                            duration:5000,
                        });
                    }
                    else {
                        plazo = values.Pws_Val_pla;
                        const valorToFinanciar = String(parseFloat(monto));
                        let simulador = await WLOCreditAPIService.getSimulador({
                            pws_monto: monto!,
                            pws_nro_cuotas: values.pws_nro_cuotas!,
                            pws_fecha_sol: new Date().toDateString(),
                            pws_cod_credi: values.pws_cod_credi!,
                            pws_form_plazo: plazo,
                            pws_identific: values.Pws_Identificacion,
                            pws_form_periodic: plazo
                        });
                        
                       if (!_.isEmpty(simulador.payload) && !_.isEmpty(simulador.payload.data)) {
                            const valorQuote = simulador.payload.data.R_Val_cuota;
                            values.Pws_Val_cuo=valorQuote;
                            values.Pws_Val_finan=valorToFinanciar;
                            setValueToFinance(parseFloat(valorToFinanciar));
                            setValueQuote(valorQuote);
                            const infoCredit = simulador;  
                            setRequest({
                                ...request,
                                valueOfInicialQuote: "0",
                                valueOfQuote: valorQuote,
                                valueToFinance: monto.toString(),
                                simulator: infoCredit,
                                isRotativo:Boolean(listCredit.filter((valorflitro:any)=>valorflitro.s_codigo==values.pws_cod_credi)[0]['s_rotativo'])
                            });
                            toast.success('Se ha generado un nuevo calculo de cuota',{
                                duration:5000,
                            });
                            handleSendEmail(valorQuote)
                            setIsSimulator(false);
                        }
                    }
                } catch (e) {
                    setIsSimulator(true);
                    toast.error('En este momento no podemos atender su solicitud',{
                        duration:5000,
                    });
                    setTimeout(() => {
                        setIsSimulator(false);
                    }, 4000);
                }

            }
        }
    }


    return (
        <div className={'row'}>
            <ClockLoader id='spinner' color={"#145CCC"} loading={isLoadingSimulator} size={50}/>
            <ClockLoader id='spinner' color={"#145CCC"} loading={isLoadingEmail} size={50}/>
            <div>
                <img className="img-fluid" src={request.imagemoto} alt={''}/>
            </div>
            <div
                className={"title-large-bold-mobile"}>{intl.formatMessage({id: "form_credit_information_promotion_title"})}</div>
            {/* <div id={"model-product"}
                 className={"title-large-red-mobile"}>{request.nameProduct}</div> */}
            {/* <div id={"price-product"}
                 className={"title-large-mobile"}><NumberFormat value={parseFloat(request.priceProduct!)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></div> */}
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <div className={"form-know-you-item-note-mobile"}>
                        {intl.formatMessage({id: "form_credit_information_promotion_subtitle"})}
                    </div>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <div className={"subtitle-medium-mobile"}>{intl.formatMessage({id: "option_credit"})}</div>
                    <Field as="select"
                        id={"pws_cod_credi"}
                        className={`${errors.pws_cod_credi ? 'form-know-you-item-input-mobile input-error' : 'form-know-you-item-input-mobile'}`}
                        name="pws_cod_credi">
                        <option>{intl.formatMessage({id: "option_credit"})}</option>
                        {listCredit && listCredit.map((item:any, key: number) => {
                            return (
                                <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                            )
                        })}
                    </Field>
                </div>
            </div>
            <div className="col-3"/>
            {/* <div className="col-6">
                <div
                    className={"subtitle-medium-mobile"}>{intl.formatMessage({id: "form_credit_information_selection"})}</div>
            </div> */}
            <div className="col-3"/>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                <div className={"label-input-left text-center"}>{intl.formatMessage({id: "value_to_request"})}</div>
                    <Field id={"Pws_monto"}
                        name={"Pws_monto"}
                        component={ParseNumberThousandMedium}
                        placeholder={intl.formatMessage({id: "placeholder_initial_quote_symbol"})}
                        className={'form-know-you-item-input-mobile'}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                <div className={"label-input-left text-center"}>{intl.formatMessage({id: "nro_quotes"})}</div>
                <Field  as="select"
                        id={"pws_nro_cuotas"}
                        name={"pws_nro_cuotas"}
                        className={`${errors.pws_nro_cuotas ? 'form-know-you-item-input-medium display-flex-cuota input-error' : 'form-know-you-item-input-medium display-flex-cuota'}`}
                        disabled={isLoadingSimulator}>
                        <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                        <option value={'6'}>{'6'}</option>
                        <option value={'12'}>{'12'}</option>
                        <option value={'24'}>{'24'}</option>
                        <option value={'36'}>{'36'}</option>
                        <option value={'48'}>{'48'}</option>
                        <option value={'60'}>{'60'}</option>
                    </Field>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                <div className={"label-input-left text-center"}>{intl.formatMessage({id: "term"})}</div>
                    <Field as="select" id={"Pws_Val_pla"}
                        className={`${errors.Pws_Val_pla ? 'form-know-you-item-input-mobile input-error' : 'form-know-you-item-input-mobile'}`}
                        name="Pws_Val_pla">
                        <option>{intl.formatMessage({id: "term"})}</option>
                        {listPeriodos && listPeriodos.map((item:any, key: number) => {
                            if (item.s_codigo === '1') {
                            return (
                                <option key={key} value={item.s_descripcion}>{item.s_descripcion}</option>
                            )
                        }})}
                    </Field>
                </div>
            </div>
            {/* <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                <div className={"label-input-left text-center"} style={{"paddingLeft":"0px"}}>{intl.formatMessage({id: "value_of_quote"})}</div>
                    <div className="roundNumberFormat " >
                    <NumberFormat value={parseFloat(request.valueToFinance!)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>

                    </div>
                </div>
            </div> */}
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                <div className="roundNumberFormat text-center">
                    <NumberFormat value={parseFloat(request.valueOfQuote!)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                    </div>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <div className={"form-know-you-item-button"} onClick={handleCalculatedQuote}>
                        {intl.formatMessage({id: "button_calculate_quote"})}
                    </div>
                </div>
            </div>
            <div className="col-3"/>
            <div className="col-6">
                <div className={"subtitle-medium-mobile"}>
                    {/* {intl.formatMessage({id: "form_credit_information_note"})} */}
                </div>
            </div>
            <div className="col-4"/>
         
        </div>
        
    )
}